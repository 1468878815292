import { FC, forwardRef, useEffect, useState } from 'react'
import { IMaskInput } from 'react-imask'

import Modal from '@mui/material/Modal'
import {
  Button,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CountrySelect from './CountrySelect'
import { request } from '../../utils/fetcher'

const TextMaskCustom = forwardRef<HTMLInputElement, any>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="(00)#00000000"
      definitions={{
        '#': /[1-9]/,
      }}
      unmask={true}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

const NewMessageModal: FC<{
  opened: boolean
  setores: any
  handleClose: () => void
}> = ({ opened, setores, handleClose }) => {
  const DEFAULT = {
    name: '',
    phone: '',
    country: { code: 'BR', label: '(55) Brasil', phone: '55' },
    setor: '',
    device_id: '',
  }
  const [post, setPost] = useState(DEFAULT)
  const [error, setError] = useState('')
  const [devices, setDevices] = useState([])

  useEffect(() => {
    loadAllDevices()
  }, [])

  useEffect(() => {
    if (opened === false) setPost(DEFAULT)
  }, [opened])

  const loadAllDevices = async () => {
    const data = await request(`company/get-devices`, {}, {}, { method: 'GET' })
    setDevices(data.devices)
  }

  const handleChange = (e: any) => {
    setPost({ ...post, [e.target.name]: e.target.value })
  }

  const handleChangeCountry = (option: any) => {
    setPost({ ...post, country: option })
  }

  const handleSave = async () => {
    setError('')

    let error = []

    if (post.name === '') error.push('Nome')
    if (post.phone === '') error.push('Telefone')
    if (post.country === null) error.push('País')
    if (post.device_id === '') error.push('Telefone da Empresa')
    if (post.setor === '') error.push('Departamento')

    if (error.length > 0) {
      setError('Preencha os campos:' + error.join(', ') + '.')
    } else {
      const body = {
        name: post.name,
        phone: post.phone,
        country: post.country.phone,
        setor: post.setor,
        device_id: post.device_id,
      }

      const response = await request(`message/new`, body)

      if (response.success) {
        document.location.href = `/chat/${response.clientId}`
      } else {
        setError(response.message)
      }
    }
  }

  return (
    <Modal
      open={opened}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            minWidth: '300px',
            maxWidth: '90%',
            maxHeight: '90%',
            background: '#fff',
            borderRadius: '8px',
            position: 'relative',
            padding: '40px 15px',
          }}
        >
          <div style={{ position: 'absolute', right: 0, top: 0 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <Typography
            variant="h4"
            color={'primary'}
            style={{ fontSize: 20, padding: '7px', marginBottom: '15px', textAlign: 'center', fontWeight: 'bold' }}
          >
            Cadastrar Novo Chat
          </Typography>

          <div
            style={{
              width: '280px',
              height: '30px',
              margin: '0 auto',
              textAlign: 'center',
              fontSize: '12px',
              color: 'red',
            }}
          >
            {error !== '' ? `${error}` : ''}
          </div>

          <FormGroup>
            <FormControl fullWidth size="small" sx={{ margin: '10px 0' }}>
              <TextField
                id="text-name"
                name="name"
                label="Nome *"
                variant="outlined"
                size="small"
                value={post['name']}
                onChange={handleChange}
              />
            </FormControl>

            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <FormControl size="small" sx={{ margin: '10px 0', width: '110px', marginRight: '10px' }}>
                <CountrySelect defaultValue={post['country']} onChange={handleChangeCountry} />
              </FormControl>

              <FormControl size="small" sx={{ margin: '10px 0', width: '200px' }}>
                <InputLabel htmlFor="formatted-text-mask-input">Telefone *</InputLabel>
                <OutlinedInput
                  label="Telefone *"
                  value={post['phone']}
                  onChange={handleChange}
                  name="phone"
                  size="small"
                  id="formatted-text-mask-input-phone"
                  inputComponent={TextMaskCustom as any}
                />
              </FormControl>
            </div>

            <FormControl fullWidth size="small" sx={{ margin: '10px 0' }}>
              <InputLabel id="assing-user-simple-select-label-devices">Telefone da Empresa *</InputLabel>
              <Select
                labelId="assing-user-simple-select-label-devices"
                label="Telefone da Empresa *"
                name="device_id"
                value={post['device_id']}
                onChange={handleChange}
                displayEmpty
              >
                {devices.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small" sx={{ margin: '10px 0' }}>
              <InputLabel id="assing-user-simple-select-label-setores">Departamento *</InputLabel>
              <Select
                labelId="assing-user-simple-select-label-setores"
                label="Departamento *"
                name="setor"
                value={post['setor']}
                onChange={handleChange}
                displayEmpty
              >
                {setores.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div style={{ margin: '25px 0 0 0', textAlign: 'right' }}>
              <Button
                onClick={() => {
                  handleSave()
                }}
                variant="contained"
                size="small"
                sx={{ width: 'auto' }}
              >
                Criar Chat
              </Button>
            </div>
          </FormGroup>
        </div>
      </div>
    </Modal>
  )
}

export default NewMessageModal

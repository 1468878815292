import { FC, useContext, useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Avatar from '@mui/material/Avatar'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle'
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import Exhibition from './Exhibition'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import { request } from '../../../utils/fetcher'
import dayjs, { Dayjs } from 'dayjs'
import CircleIcon from '@mui/icons-material/Circle'

import Messages from './Messages'
import TextForm from './TextForm'
import Modal from './Modal'
import { DataContext } from '../../../context'
import SelectGroup from '../../SelectGroup'
import { useParams } from 'react-router-dom'

type optionsType = {
  value: number
  label: string
  color: 'info' | 'error' | 'warning'
  action: () => void
}

const UserChat: FC<{
  data?: any
  tags: any
  setores: any
  users: any
  handleChatData: (id: string) => void
}> = ({ data, tags, setores, users, handleChatData }) => {
  const { id: idParam } = useParams()
  const listRef = useRef<any>(null)
  const menuRef = useRef<any>(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [tag, setTag] = useState([] as any)
  const [department_fixed, setDepartmentFixed] = useState('')
  const [employee_fixed, setEmployeeFixed] = useState('')
  const [employee_fixed_max_date, setEmployeeFixedMaxDate] = useState(0)
  const open = Boolean(anchorEl)
  const [modalOpen, setModalOpen] = useState('')
  const [queue, setQueue] = useState(0)
  const [name, setName] = useState('')
  const [clientPhone, setClientPhone] = useState('')
  const [scheduleDate, setScheduleDate] = useState<Dayjs | null>(null)
  const [actualStatus, setActualStatus] = useState(1)
  const [statusValues, setStatusValues] = useState<any>(null)
  const [queue_user, setQueueUser] = useState('')
  const [clientId, setClientId] = useState('')
  const [chatId, setChatId] = useState('')
  const [chatEmployeeId, setChatEmployeeId] = useState(0)
  const [employeeName, setEmployeeName] = useState('')
  const [employeeOnlineStatus, setEmployeeOnlineStatus] = useState(0)
  const [chatDateClose, setChatDateClose] = useState(0)
  const [chatIsMine, setChatIsMine] = useState(0)
  const [queueIsMine, setQueueIsMine] = useState(0)
  const [deviceId, setDeviceId] = useState('')
  const [avatar, setAvatar] = useState('')
  const [scheduleMessageCount, setScheduleMessageCount] = useState(0)
  const [employee_fixed_name, setEmployeeFixedName] = useState('')
  const [department_fixed_name, setDepartmentFixedName] = useState('')
  const [loading, setLoading] = useState(true)
  const { refreshChatList } = useContext(DataContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (parseInt(idParam || '') === parseInt(data.client_id)) {
      const {
        status_type,
        status_label,
        status_date_validity,
        status_message_toEndChat,
        status_abortIFmessageReceived,
      } = data

      setTag(data.tags ? data.tags.split(',').map((val: any) => parseInt(val)) : ([] as any))
      setDepartmentFixed(data.department_fixed ? data.department_fixed : '')
      setEmployeeFixed(data.employee_fixed ? data.employee_fixed : '')
      setEmployeeFixedMaxDate(data.employee_fixed_max_date ? data.employee_fixed_max_date : 0)
      setQueue(data.queue)
      setName(data.exhibition_name)
      setActualStatus(data.status_type)
      setStatusValues({
        status_type,
        status_label,
        status_date_validity,
        status_message_toEndChat,
        status_abortIFmessageReceived,
      })
      setQueueUser(data.queue_user)
      setQueue(data.queue)
      setClientPhone(data.client_phone)
      setClientId(data.client_id)
      setChatId(data.id)
      setChatEmployeeId(data.chat_employee_id)
      setChatDateClose(data.chat_date_close)
      setChatIsMine(data.chat_is_mine)
      setQueueIsMine(data.queue_is_mine)
      setDeviceId(data.device_id)
      setAvatar(data.avatar)
      setScheduleMessageCount(data.schedule_messages_count)
      setEmployeeFixedName(data.employee_fixed_name)
      setEmployeeName(data.chat_employee_name)
      setDepartmentFixedName(data.department_fixed_name)

      const datetime = new Date().toLocaleString('en', { timeZone: 'America/Sao_Paulo' })
      const dateObj = new Date(datetime)
      const now = Math.floor((dateObj.getTime() - 60 * 1000) / 1000)

      setEmployeeOnlineStatus(now < data.chat_employee_online_status ? 1 : 0)

      setLoading(false)
    }
  }, [data])

  if (loading) return null

  const handleCloseModal = () => {
    setModalOpen('')
  }

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const addToQueue = async () => {
    await request(`messages/${chatId}/add-to-queue`)
    handleChatData(clientId)
    refreshChatList(true)
  }

  const removeToQueue = async () => {
    await request(`messages/${chatId}/remove-to-queue`)
    handleChatData(clientId)
    refreshChatList(true)
  }

  const saveTags = async (tags: string[]) => {
    await request(`messages/${chatId}/tags`, { tags })
  }

  const handleChangeTags = (item: any) => {
    let allSelected = tags.filter((t: any) => tag.includes(t.value))

    if (tag.includes(item.value)) {
      allSelected = allSelected.filter((t: any) => t.value !== item.value)
    } else {
      if (item.group_type === 1) {
        allSelected = allSelected.filter((t: any) => t.group_type === 0)
      }

      allSelected.push(item)
    }

    const allTagsIds = allSelected.map((t: any) => t.value)

    setTag(allTagsIds)
    saveTags(allTagsIds)
  }

  const getAvatar = (avatar: any, exhibition_name: string) => {
    if (avatar !== '') return <Avatar alt={exhibition_name} sx={{ width: 45, height: 45 }} src={avatar} />
    else return <AccountCircleRoundedIcon sx={{ width: 45, height: 45 }} />
  }

  const start = async () => {
    await request(`client/${clientId}/start`)
    handleChatData(clientId)
    refreshChatList(true)
  }

  const finish = async () => {
    await request(`client/${clientId}/finish`)
    handleChatData(clientId)
    refreshChatList(true)
  }

  const read = async () => {
    await request(`chat/${clientId}/markAsRead`)
    refreshChatList(true)
  }

  const unread = async () => {
    await request(`chat/${clientId}/markAsUnread`)
    navigate(`/chats`)
    refreshChatList(true)
  }

  const isDisabled = chatEmployeeId === 0 || chatDateClose > 0
  const options = []

  if (chatEmployeeId === 0 || chatDateClose > 0) options.push({ name: 'Iniciar', action: start })

  if (chatEmployeeId === 0 || chatIsMine === 1)
    options.push({
      name: 'Transferir',
      action: () => {
        setModalOpen('transfer')
      },
    })

  if (chatEmployeeId === 0 || chatIsMine === 1)
    options.push({
      name: 'Finalizar',
      action: () => {
        finish()
      },
    })

  options.push({
    name: 'Marcar como lido',
    action: () => {
      read()
    },
  })
  options.push({
    name: 'Marcar como não lido',
    action: () => {
      unread()
    },
  })

  const headerHeight = '92px'
  const textFormHeight = '100px'

  const handleEmAtendimento = async () => {
    if (actualStatus && Number(actualStatus) !== 1) {
      setActualStatus(1)
      await request(`chat/${chatId}/status`, { status: 1, statusLabel: 'Atendendo' })
      refreshChatList(true)
    }
  }

  const getFixedUserDate = (date: number) => {
    return dayjs.unix(date).format('DD/MM/YYYY HH:mm')
  }

  const getUserNameAndLastName = (name: string) => {
    const employee = name.split(' ')

    return employee[0] + ' ' + employee[employee.length - 1]
  }

  const statusOptions = [
    { value: 1, label: 'Atendendo', color: 'info', action: handleEmAtendimento },
    { value: 2, label: 'Adiado', color: 'warning', action: () => setModalOpen('adiar') },
    { value: 3, label: 'Finalizando', color: 'error', action: () => setModalOpen('agendarFinalizar') },
  ] as optionsType[]

  return (
    <>
      <Modal
        clientId={clientId}
        chatId={chatId}
        clientPhone={clientPhone}
        deviceId={deviceId}
        modalOpen={modalOpen}
        handleClose={handleCloseModal}
        setores={setores}
        users={users}
        department_fixed={department_fixed}
        employee_fixed={employee_fixed}
        employee_fixed_max_date={employee_fixed_max_date}
        scheduleDate={scheduleDate}
        setEmployeeFixed={setEmployeeFixed}
        setEmployeeFixedMaxDate={setEmployeeFixedMaxDate}
        setDepartmentFixed={setDepartmentFixed}
        setDateSchedule={setScheduleDate}
        refreshChatList={refreshChatList}
        status={{
          ...statusValues,
          setActualStatus,
        }}
      />

      <Grid mb={2} mt={2} ml={1} mr={1} sx={{ height: headerHeight, maxHeight: headerHeight, minHeight: headerHeight }}>
        <div
          className="header-chat"
          style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}
        >
          <div className="mobile-full" style={{ display: 'flex', width: '55%', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ marginRight: '5px' }}>{getAvatar(avatar, name)}</div>
              <div>
                <Exhibition id={clientId} name={name} />

                <Typography component="span" sx={{ fontSize: '12px' }} color="gray">
                  {clientPhone}
                </Typography>
              </div>
            </div>
          </div>
          <div className="mobile-full" style={{ display: 'flex', width: '45%', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', height: 30 }}>
              {!isDisabled && (
                <SelectGroup selected={actualStatus ? Number(actualStatus) : 1} options={statusOptions} />
              )}

              {
                // TODO:
                // if is a manager could see this button
                // if a manager has pinned a person, everyone see the icon
              }
              <IconButton
                color={employee_fixed === '' ? 'primary' : 'success'}
                title={
                  employee_fixed === ''
                    ? 'Fixar apenas ao Usuário'
                    : `Chat está fixado com ${employee_fixed_name} até ${getFixedUserDate(employee_fixed_max_date)}`
                }
                onClick={() => {
                  setModalOpen('atribuir-usuario')
                }}
              >
                <PersonPinCircleIcon sx={{ width: 22, height: 22 }} />
              </IconButton>

              {
                // TODO:
                // if is a manager could see this button
                // if a manager has pinned a sector, everyone see the icon
              }
              <IconButton
                color={department_fixed === '' ? 'primary' : 'success'}
                title={
                  department_fixed === ''
                    ? 'Fixar com um Departamento'
                    : `Chat está fixado ao departamento ${department_fixed_name}`
                }
                onClick={() => {
                  setModalOpen('atribuir-setor')
                }}
              >
                <FolderSpecialIcon sx={{ width: 22, height: 22 }} />
              </IconButton>

              {((chatIsMine === 0 && chatEmployeeId > 0) || (chatIsMine === 1 && queue > 0)) && (
                <IconButton
                  color={queue === 0 ? 'primary' : 'success'}
                  title={
                    queue === 0
                      ? 'Entrar na fila de espera'
                      : queueIsMine === 1
                      ? 'Sair da fila de espera'
                      : `${queue_user} está na fila de espera`
                  }
                  onClick={() => {
                    if (chatIsMine !== 1 && queue === 0) {
                      addToQueue()
                    }
                    //else if (queue_is_mine === 1 || user_permission > do user que adicionou) {
                    else if (queueIsMine === 1) {
                      removeToQueue()
                    }
                  }}
                >
                  <TransferWithinAStationIcon sx={{ width: 22, height: 22 }} />
                </IconButton>
              )}

              <IconButton
                ref={menuRef}
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ color: '#000' }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: '20ch',
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.name}
                    onClick={() => {
                      option.action && option.action()
                      handleClose()
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            {chatEmployeeId !== 0 && (
              <div
                style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }}
              >
                <span title={employeeOnlineStatus === 0 ? 'offline' : 'online'}>
                  <CircleIcon color={employeeOnlineStatus === 0 ? 'error' : 'success'} sx={{ fontSize: 11 }} />
                </span>
                &nbsp;
                <span style={{ fontSize: 11, paddingRight: 15, lineHeight: 1 }}>
                  Em atendimento: {getUserNameAndLastName(employeeName)}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="mobile-hide" style={{ display: 'flex', width: '100%' }}>
          <FormControl variant="standard" sx={{ width: '100%', margin: '0 10px' }}>
            <InputLabel id="demo-simple-select-standard-label">
              <LocalOfferIcon sx={{ width: 20, height: 20 }} color="primary" />
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={tag}
              renderValue={(selected: any) => {
                return tags
                  .filter((item: any) => selected.indexOf(item.value) > -1)
                  .map((item: any) => item.name.split(' > ')[1])
                  .join(', ')
              }}
              size="small"
              displayEmpty
            >
              {tags.map((item: any) => (
                <MenuItem key={item.value} value={item.value} onClick={() => handleChangeTags(item)}>
                  <Checkbox checked={tag && tag.indexOf(item.value) > -1} />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Grid>

      <Divider />

      <Grid
        ref={listRef}
        style={{ height: `calc(100% - ${headerHeight} - ${textFormHeight} - 34px)`, overflow: 'auto' }}
      >
        <Messages
          key={clientId}
          scrollRef={listRef}
          id={clientId}
          scheduleMessagesCount={scheduleMessageCount}
          handleScheduleMessages={() => {
            setModalOpen('mensagens-agendadas')
          }}
          refreshChatData={() => handleChatData(clientId)}
        />
      </Grid>

      <Divider />

      <TextForm
        id={clientId}
        disabled={isDisabled}
        scheduleDate={scheduleDate}
        clearScheduleDate={() => {
          setScheduleDate(null)
        }}
        handleScheduleAction={() => {
          setModalOpen('agendar-mensagem')
        }}
        areaHeight={textFormHeight}
        disabledAction={() => {
          menuRef.current?.click()
        }}
      />
    </>
  )
}

export default UserChat

import { FC, useState, forwardRef, useEffect } from 'react'
import {
  Alert,
  Button,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { IMaskInput } from 'react-imask'
import { request } from '../../utils/fetcher'
import theme from '../../styles/themes'

const TextMaskCustom = forwardRef<HTMLInputElement, any>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="(00)#00000000"
      definitions={{
        '#': /[1-9]/,
      }}
      unmask={true}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

const Users: FC = () => {
  const week = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom']

  const defaultPost = {
    id: '',
    name: '',
    display_name: '',
    email: '',
    phone: '',
    password: '',
    departments: [],
    activate_access: false,
  }

  const [tab, setTab] = useState(0)
  const [post, setPost] = useState(defaultPost as any)
  const [error, setError] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [departments, setDepartments] = useState([])

  useEffect(() => {
    if (tab === 0) {
      handleClear()
      loadAllUsers()
    } else if (tab === 2) {
      loadAllDepartments()
    }
  }, [tab])

  const loadAllUsers = async () => {
    setLoading(true)
    const data = await request(`company/get-users/`, {}, {}, { method: 'GET' })

    if (data.success && data.users.length > 0) setUsers(data.users)
    setLoading(false)
  }

  const loadUserAccessTime = async (userId: number) => {
    let newPost = {} as any
    const data = await request(`company/access-time/${userId}`, {}, {}, { method: 'GET' })

    if (data.success) {
      data.access_time.forEach((time: any) => {
        let w = null

        switch (time.permissions_employee_access_time_work_day) {
          case 1:
            w = 'Seg'
            break
          case 2:
            w = 'Ter'
            break
          case 3:
            w = 'Qua'
            break
          case 4:
            w = 'Qui'
            break
          case 5:
            w = 'Sex'
            break
          case 6:
            w = 'Sab'
            break
          case 7:
            w = 'Dom'
            break
          default:
            break
        }

        if (w) {
          newPost[`activate_access_${w}`] = true
          newPost[`from_${w}`] = time.permissions_employee_access_time_hour_start
          newPost[`to_${w}`] = time.permissions_employee_access_time_hour_end
        }
      })
    }

    return newPost
  }

  const loadAllDepartments = async () => {
    const data = await request(`company/get-all-company-departments/`, {}, {}, { method: 'GET' })

    if (data.success && data.departments.length > 0) setDepartments(data.departments)
  }

  const onChange = (e: any) => {
    setPost({ ...post, [e.target.name]: e.target.value })
  }

  const handleClear = () => {
    setPost(defaultPost as any)
    setError('')
    setSuccessMsg('')
  }

  const handleSubmit = async () => {
    setError('')
    setSuccessMsg('')

    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    if (post.name === '') setError('Preencha o Nome.')
    else if (post.email === '' || !emailRegex.test(post.email)) setError('Preencha com um E-mail válido.')
    else if (post.password === '') setError('Preencha a Senha.')
    else {
      setLoading(true)

      const resp = await request(`company/add-user`, post)

      setLoading(false)

      if (!resp.success) {
        setError(resp.error)
      } else {
        setPost(defaultPost as any)

        if (resp.message !== '') {
          setSuccessMsg(resp.message)
        }
      }
    }
  }

  const handleSubmitEditUser = async () => {
    setError('')
    setSuccessMsg('')

    if (post.departments.lenght === 0) setError('Selecione um Departamento.')
    else {
      setLoading(true)
      const resp = await request(`company/edit-user`, post)

      setLoading(false)

      if (!resp.success) {
        setError(resp.error)
      } else {
        if (resp.message !== '') {
          setSuccessMsg(resp.message)
        }
      }
    }
  }

  const handleEdit = async (user: any) => {
    setTab(2)
    setLoading(true)
    const access_time = await loadUserAccessTime(user.employee_id as number)
    setPost({
      ...post,
      ...access_time,
      id: user.employee_id,
      name: user.employee_name,
      email: user.employee_mail,
      display_name: user.display_name || '',
      activate_access: user.restrict_access === 1 ? true : false,
      departments: user.departments ? user.departments.split(',').map((d: any) => parseInt(d)) : 0,
    })
    setLoading(false)
  }

  return (
    <div>
      {tab === 0 && (
        <>
          <div style={{ textAlign: 'right', marginBottom: 20 }}>
            <Button
              onClick={() => {
                setTab(1)
              }}
              variant="contained"
              size="small"
              sx={{ width: 'auto' }}
            >
              Adicionar Usuário &nbsp; <AddCircleOutlineIcon fontSize={'small'} />
            </Button>
          </div>
          <div>
            {loading && <LinearProgress />}
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width="40%"> Nome </TableCell>
                    <TableCell width="30%"> Email </TableCell>
                    <TableCell width="20%"> Data de Cadastro </TableCell>
                    <TableCell width="10%"> &nbsp; </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((u: any, i: number) => {
                    return (
                      <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{u.employee_name}</TableCell>
                        <TableCell>{u.employee_mail}</TableCell>
                        <TableCell>{u.created_at}</TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                          {/* {u.isMe === 0 && ( */}
                          <Fab onClick={() => handleEdit(u)} size="small" color="primary">
                            <EditIcon sx={{ fontSize: 14 }} />
                          </Fab>
                          {/* )} */}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
      {tab === 1 && (
        <>
          <div style={{ textAlign: 'right' }}>
            <IconButton onClick={() => setTab(0)}>
              <CloseIcon />
            </IconButton>
          </div>

          <FormGroup>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    name="name"
                    size="small"
                    label="Nome *"
                    value={post['name']}
                    autoComplete="off"
                    onChange={onChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <FormControl fullWidth size="small">
                    <TextField
                      name="email"
                      type="email"
                      size="small"
                      label="E-mail *"
                      value={post['email']}
                      onChange={onChange}
                      disabled={post['action'] === 'update'}
                    />
                  </FormControl>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <FormControl fullWidth size="small">
                    <InputLabel htmlFor="formatted-text-mask-input">Telefone</InputLabel>
                    <OutlinedInput
                      label="Telefone"
                      value={post['phone']}
                      onChange={onChange}
                      name="phone"
                      size="small"
                      id="formatted-text-mask-input-phone"
                      inputComponent={TextMaskCustom as any}
                    />
                  </FormControl>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <FormControl fullWidth size="small">
                    {post['action'] !== 'update' && (
                      <TextField
                        name="password"
                        type="password"
                        size="small"
                        label="Senha *"
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        value={post['password']}
                        onChange={onChange}
                      />
                    )}
                  </FormControl>
                </FormControl>
              </Grid>
            </Grid>
          </FormGroup>

          <div style={{ margin: '40px 0', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                handleClear()
              }}
              size="small"
              sx={{ width: 'auto' }}
            >
              Limpar
            </Button>

            <Button
              onClick={() => {
                handleSubmit()
              }}
              disabled={loading}
              variant="contained"
              size="small"
              sx={{ width: 'auto' }}
            >
              Salvar
            </Button>
          </div>
          {loading && <LinearProgress />}
          {error !== '' && <Alert severity="error">{error}</Alert>}
          {successMsg !== '' && (
            <Alert severity="success">
              <div dangerouslySetInnerHTML={{ __html: successMsg }} />
            </Alert>
          )}
        </>
      )}
      {tab === 2 && (
        <>
          <div style={{ textAlign: 'right' }}>
            <IconButton onClick={() => setTab(0)}>
              <CloseIcon />
            </IconButton>
          </div>
          {loading && <LinearProgress />}
          {post.id === 0 && <LinearProgress />}

          {post.id > 0 && (
            <>
              <FormGroup>
                <Grid container spacing={2} sx={{ padding: '0 20px', gap: '20px' }}>
                  <Grid item xs={12}>
                    <h4
                      style={{
                        margin: '0 0 3px 0',
                        fontSize: 18,
                        fontWeight: '700',
                        color: theme.colors.primary,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {post.name}
                    </h4>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: '400',
                        color: '#000',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {post.email}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                      <TextField
                        name="display_name"
                        size="small"
                        label="Nome visível para o cliente"
                        value={post['display_name']}
                        autoComplete="off"
                        onChange={onChange}
                      />
                    </FormControl>
                    <span style={{ fontSize: 12, padding: '0 5px', color: '#666' }}>
                      Este nome será inserido automaticamente antes da mensagem caso preenchido.
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="select-setor">Departamentos *</InputLabel>
                      <Select
                        labelId="select-setor"
                        multiple
                        name="departments"
                        label="Departamentos *"
                        value={post['departments'] || []}
                        input={<OutlinedInput label="Departamentos *" />}
                        onChange={onChange}
                        renderValue={(selected) => {
                          return departments
                            .filter((item: any) => selected.indexOf(item.value) > -1)
                            .map((item: any) => item.name)
                            .join(', ')
                        }}
                        displayEmpty
                      >
                        {departments.map((item: any) => (
                          <MenuItem key={item.value} value={item.value}>
                            <Checkbox checked={post['departments'] && post['departments'].indexOf(item.value) > -1} />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ flexDirection: 'row', display: 'flex', gap: '20px', marginBottom: '20px' }}>
                      <h4
                        style={{
                          margin: '0 0 3px 0',
                          fontSize: 16,
                          fontWeight: '700',
                          color: '#000',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        Restringir Acesso
                      </h4>
                      <FormControlLabel
                        name="activate_access"
                        onChange={(e: any) => {
                          setPost({ ...post, [e.target.name]: e.target.checked })
                        }}
                        control={<Switch checked={post['activate_access']} size="small" />}
                        label={post['activate_access'] ? 'Ativo' : 'Inativo'}
                      />
                    </div>

                    {post['activate_access'] && (
                      <TableContainer component={Paper}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell> &nbsp; </TableCell>
                              {week.map((w, i) => (
                                <TableCell key={i}>
                                  <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    {w}
                                    <FormControlLabel
                                      label=""
                                      name={`activate_access_${w}`}
                                      onChange={(e: any) => {
                                        setPost({ ...post, [e.target.name]: e.target.checked })
                                      }}
                                      control={<Switch checked={post[`activate_access_${w}`]} size="small" />}
                                    />
                                  </div>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell>De</TableCell>
                              {week.map((w, i) => (
                                <TableCell key={`from_${w}`}>
                                  <FormControl fullWidth size="small">
                                    <TextField
                                      name={`from_${w}`}
                                      size="small"
                                      value={post[`from_${w}`]}
                                      onChange={onChange}
                                      type="time"
                                      style={{ width: 110 }}
                                      disabled={!post[`activate_access_${w}`]}
                                    />
                                  </FormControl>
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell>Até</TableCell>
                              {week.map((w, i) => (
                                <TableCell key={`to_${w}`}>
                                  <FormControl fullWidth size="small">
                                    <TextField
                                      name={`to_${w}`}
                                      size="small"
                                      value={post[`to_${w}`]}
                                      onChange={onChange}
                                      type="time"
                                      style={{ width: 110 }}
                                      disabled={!post[`activate_access_${w}`]}
                                    />
                                  </FormControl>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                </Grid>
              </FormGroup>

              <div style={{ margin: '40px 0', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => {
                    handleSubmitEditUser()
                  }}
                  disabled={loading}
                  variant="contained"
                  size="small"
                  sx={{ width: 'auto' }}
                >
                  Salvar
                </Button>
              </div>
              {loading && <LinearProgress />}
              {error !== '' && <Alert severity="error">{error}</Alert>}
              {successMsg !== '' && (
                <Alert severity="success">
                  <div dangerouslySetInnerHTML={{ __html: successMsg }} />
                </Alert>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Users

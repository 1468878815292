import { FC, useState } from 'react'
import { Alert, Box, Button, FormControl, FormGroup, Grid, LinearProgress, TextField } from '@mui/material'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'

import Layout from '../../components/Layout'
import theme from '../../styles/themes'
import { request } from '../../utils/fetcher'

const Profile: FC = () => {
  const defaultPost = {
    password: '',
    password_r: '',
  }

  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState(defaultPost as any)
  const [error, setError] = useState('')

  const handleClear = () => {
    setPost(defaultPost as any)
    setError('')
  }

  const handleSubmit = async () => {
    if (post.password.trim() === '') setError('Preencha a Nova Senha.')
    else if (post.password_r.trim() === '') setError('Preencha a repetição da Nova Senha.')
    else if (post.password !== post.password_r) setError('A repetição de senha precisa ser igual.')
    else {
      setLoading(true)

      await request(`user/update-password`, {
        password: post.password,
      })

      setLoading(false)
      handleClear()
    }
  }

  const onChange = (e: any) => {
    setPost({ ...post, [e.target.name]: e.target.value })
    setError('')
  }

  return (
    <div
      style={{
        overflow: 'auto',
      }}
    >
      <Layout>
        {loading && <LinearProgress />}
        <Box
          sx={{
            maxWidth: '100%',
            margin: '20px',
          }}
        >
          <h4
            style={{
              padding: '0 20px',
              margin: '0 0 20px 0',
              fontSize: 16,
              fontWeight: '400',
              color: theme.colors.primary,
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <ManageAccountsIcon /> Perfil
          </h4>

          <FormGroup>
            <Grid container spacing={2} sx={{ padding: '0 20px' }}>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    name="password"
                    size="small"
                    type="password"
                    label="Nova Senha *"
                    value={post['password']}
                    onChange={onChange}
                    autoComplete="no"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    name="password_r"
                    size="small"
                    label="Repetir Senha *"
                    type="password"
                    value={post['password_r']}
                    onChange={onChange}
                    autoComplete="no"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </FormGroup>

          {error !== '' && (
            <div style={{ padding: '20px' }}>
              <Alert severity="error">{error}</Alert>
            </div>
          )}

          <div style={{ margin: '40px 20px', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                handleClear()
              }}
              size="small"
              sx={{ width: 'auto' }}
              disabled={loading}
            >
              Limpar
            </Button>

            <Button
              onClick={() => {
                handleSubmit()
              }}
              variant="contained"
              size="small"
              sx={{ width: 'auto' }}
              disabled={loading}
            >
              Salvar
            </Button>
          </div>
        </Box>
      </Layout>
    </div>
  )
}

export default Profile

import { FC } from 'react'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { getExtension } from '../../utils/files'

const DefaultFileComponent: FC<{
  file: any
  size?: string
}> = ({ file, size = 'medium' }) => {
  const width = size === 'xsmall' ? '50' : size === 'small' ? '120' : '250'
  const height = size === 'xsmall' ? '30' : size === 'small' ? '80' : '250'
  const ext = getExtension(file.name)

  if (ext === 'jpg' || ext === 'png' || ext === 'jpeg' || ext === 'gif' || ext === 'webp') {
    return (
      <img
        alt="arquivo"
        src={file.path ? file.path : URL.createObjectURL(file)}
        width={width}
        style={{
          border: '1px solid #f8f8f8',
          boxShadow: '1px 1px 3px #ccc',
          padding: '5px',
        }}
      />
    )
  } else {
    return (
      <div
        title={file.name}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #f8f8f8',
          boxShadow: '1px 1px 3px #ccc',
          padding: '5px',
          flexDirection: 'column',
        }}
      >
        <InsertDriveFileIcon />
        <span>{file.name}</span>
      </div>
    )
  }
}

export default DefaultFileComponent

import { FC } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { InputAdornment, Popper } from '@mui/material';

const CountrySelect: FC<{ onChange: (e: any) => void, defaultValue: any }> = ({ onChange, defaultValue }) => {
  return (
    <Autocomplete
      options={countries}
      defaultValue={defaultValue}
      onChange={(e, v, r) => onChange(v)}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      getOptionLabel={(option) => `+${option.phone}`}
      filterOptions={createFilterOptions({
        stringify: (option) => `${option.label} ${option.code} ${option.phone}`,
      })}
      PopperComponent={(props) => (
        <Popper {...props} placement="bottom-start" style={{ width: '300px' }}>
          {props.children}
        </Popper>
      )}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="País *"
          name="country"
          size="small"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

const countries: readonly CountryType[] = [
    { code: 'CA', label: '(1) Canadá', phone: '1' },
    { code: 'KZ', label: '(7) Cazaquistão', phone: '7' },
    { code: 'EG', label: '(20) Egito', phone: '20' },
    { code: 'ZA', label: '(27) África do Sul', phone: '27' },
    { code: 'GR', label: '(30) Grécia', phone: '30' },
    { code: 'NL', label: '(31) Países Baixos', phone: '31' },
    { code: 'BE', label: '(32) Bélgica', phone: '32' },
    { code: 'FR', label: '(33) França', phone: '33' },
    { code: 'ES', label: '(34) Espanha', phone: '34' },
    { code: 'HU', label: '(36) Hungria', phone: '36' },
    { code: 'IT', label: '(39) Itália', phone: '39' },
    { code: 'RO', label: '(40) Romênia', phone: '40' },
    { code: 'CH', label: '(41) Suíça', phone: '41' },
    { code: 'AT', label: '(43) Áustria', phone: '43' },
    { code: 'GG', label: '(44) Guernsey', phone: '44' },
    { code: 'IM', label: '(44) Ilha de Man', phone: '44' },
    { code: 'JE', label: '(44) Jersey', phone: '44' },
    { code: 'GB', label: '(44) Reino Unido da Grã-Bretanha e Irlanda do Norte', phone: '44' },
    { code: 'DK', label: '(45) Dinamarca', phone: '45' },
    { code: 'SE', label: '(46) Suécia', phone: '46' },
    { code: 'BV', label: '(47) Ilha Bouvet', phone: '47' },
    { code: 'NO', label: '(47) Noruega', phone: '47' },
    { code: 'SJ', label: '(47) Ilhas Svalbard e Jan Mayen', phone: '47' },
    { code: 'PL', label: '(48) Polônia', phone: '48' },
    { code: 'DE', label: '(49) Alemanha', phone: '49' },
    { code: 'PE', label: '(51) Peru', phone: '51' },
    { code: 'MX', label: '(52) México', phone: '52' },
    { code: 'CU', label: '(53) Cuba', phone: '53' },
    { code: 'AR', label: '(54) Argentina', phone: '54' },
    { code: 'BR', label: '(55) Brasil', phone: '55' },
    { code: 'CL', label: '(56) Chile', phone: '56' },
    { code: 'CO', label: '(57) Colômbia', phone: '57' },
    { code: 'VE', label: '(58) Venezuela (República Bolivariana da)', phone: '58' },
    { code: 'MY', label: '(60) Malásia', phone: '60' },
    { code: 'AU', label: '(61) Austrália', phone: '61' },
    { code: 'CX', label: '(61) Ilha do Natal', phone: '61' },
    { code: 'CC', label: '(61) Ilhas Cocos (Keeling)', phone: '61' },
    { code: 'ID', label: '(62) Indonésia', phone: '62' },
    { code: 'PH', label: '(63) Filipinas', phone: '63' },
    { code: 'NZ', label: '(64) Nova Zelândia', phone: '64' },
    { code: 'SG', label: '(65) Cingapura', phone: '65' },
    { code: 'TH', label: '(66) Tailândia', phone: '66' },
    { code: 'JP', label: '(81) Japão', phone: '81' },
    { code: 'KR', label: '(82) República da Coreia', phone: '82' },
    { code: 'VN', label: '(84) Vietnã', phone: '84' },
    { code: 'CN', label: '(86) China', phone: '86' },
    { code: 'TR', label: '(90) Turquia', phone: '90' },
    { code: 'IN', label: '(91) Índia', phone: '91' },
    { code: 'PK', label: '(92) Paquistão', phone: '92' },
    { code: 'AF', label: '(93) Afeganistão', phone: '93' },
    { code: 'LK', label: '(94) Sri Lanka', phone: '94' },
    { code: 'MM', label: '(95) Mianmar', phone: '95' },
    { code: 'IR', label: '(98) Irã (República Islâmica do)', phone: '98' },
    { code: 'SS', label: '(211) Sudão do Sul', phone: '211' },
    { code: 'MA', label: '(212) Marrocos', phone: '212' },
    { code: 'EH', label: '(212) Saara Ocidental', phone: '212' },
    { code: 'DZ', label: '(213) Argélia', phone: '213' },
    { code: 'TN', label: '(216) Tunísia', phone: '216' },
    { code: 'LY', label: '(218) Líbia', phone: '218' },
    { code: 'GM', label: '(220) Gâmbia', phone: '220' },
    { code: 'SN', label: '(221) Senegal', phone: '221' },
    { code: 'MR', label: '(222) Mauritânia', phone: '222' },
    { code: 'ML', label: '(223) Mali', phone: '223' },
    { code: 'GN', label: '(224) Guiné', phone: '224' },
    { code: 'CI', label: `(225) Côte d'Ivoire`, phone: '225' },
    { code: 'BF', label: '(226) Burkina Faso', phone: '226' },
    { code: 'NE', label: '(227) Níger', phone: '227' },
    { code: 'TG', label: '(228) Togo', phone: '228' },
    { code: 'BJ', label: '(229) Benin', phone: '229' },
    { code: 'MU', label: '(230) Maurício', phone: '230' },
    { code: 'LR', label: '(231) Libéria', phone: '231' },
    { code: 'SL', label: '(232) Serra Leoa', phone: '232' },
    { code: 'GH', label: '(233) Gana', phone: '233' },
    { code: 'NG', label: '(234) Nigéria', phone: '234' },
    { code: 'TD', label: '(235) Chade', phone: '235' },
    { code: 'CF', label: '(236) República Centro-Africana', phone: '236' },
    { code: 'CM', label: '(237) Camarões', phone: '237' },
    { code: 'CV', label: '(238) Cabo Verde', phone: '238' },
    { code: 'ST', label: '(239) São Tomé e Príncipe', phone: '239' },
    { code: 'GQ', label: '(240) Guiné Equatorial', phone: '240' },
    { code: 'GA', label: '(241) Gabão', phone: '241' },
    { code: 'CG', label: '(242) Congo', phone: '242' },
    { code: 'CD', label: '(243) República Democrática do Congo', phone: '243' },
    { code: 'AO', label: '(244) Angola', phone: '244' },
    { code: 'GW', label: '(245) Guiné-Bissau', phone: '245' },
    { code: 'IO', label: '(246) Território Britânico do Oceano Índico', phone: '246' },
    { code: 'SC', label: '(248) Seychelles', phone: '248' },
    { code: 'SD', label: '(249) Sudão', phone: '249' },
    { code: 'RW', label: '(250) Ruanda', phone: '250' },
    { code: 'ET', label: '(251) Etiópia', phone: '251' },
    { code: 'SO', label: '(252) Somália', phone: '252' },
    { code: 'DJ', label: '(253) Djibouti', phone: '253' },
    { code: 'KE', label: '(254) Quênia', phone: '254' },
    { code: 'TZ', label: '(255) Tanzânia, República Unida da', phone: '255' },
    { code: 'UG', label: '(256) Uganda', phone: '256' },
    { code: 'BI', label: '(257) Burundi', phone: '257' },
    { code: 'MZ', label: '(258) Moçambique', phone: '258' },
    { code: 'ZM', label: '(260) Zâmbia', phone: '260' },
    { code: 'MG', label: '(261) Madagascar', phone: '261' },
    { code: 'TF', label: '(262) Territórios Franceses do Sul', phone: '262' },
    { code: 'YT', label: '(262) Mayotte', phone: '262' },
    { code: 'RE', label: '(262) Reunião', phone: '262' },
    { code: 'ZW', label: '(263) Zimbábue', phone: '263' },
    { code: 'NA', label: '(264) Namíbia', phone: '264' },
    { code: 'MW', label: '(265) Malaui', phone: '265' },
    { code: 'LS', label: '(266) Lesoto', phone: '266' },
    { code: 'BW', label: '(267) Botsuana', phone: '267' },
    { code: 'SZ', label: '(268) Essuatíni', phone: '268' },
    { code: 'KM', label: '(269) Comores', phone: '269' },
    { code: 'SH', label: '(290) Santa Helena', phone: '290' },
    { code: 'ER', label: '(291) Eritreia', phone: '291' },
    { code: 'AW', label: '(297) Aruba', phone: '297' },
    { code: 'FO', label: '(298) Ilhas Faroé', phone: '298' },
    { code: 'GL', label: '(299) Gronelândia', phone: '299' },
    { code: 'GI', label: '(350) Gibraltar', phone: '350' },
    { code: 'PT', label: '(351) Portugal', phone: '351' },
    { code: 'LU', label: '(352) Luxemburgo', phone: '352' },
    { code: 'IE', label: '(353) Irlanda', phone: '353' },
    { code: 'IS', label: '(354) Islândia', phone: '354' },
    { code: 'AL', label: '(355) Albânia', phone: '355' },
    { code: 'MT', label: '(356) Malta', phone: '356' },
    { code: 'CY', label: '(357) Chipre', phone: '357' },
    { code: 'FI', label: '(358) Finlândia', phone: '358' },
    { code: 'AX', label: '(358) Ilhas Åland', phone: '358' },
    { code: 'BG', label: '(359) Bulgária', phone: '359' },
    { code: 'LT', label: '(370) Lituânia', phone: '370' },
    { code: 'LV', label: '(371) Letônia', phone: '371' },
    { code: 'EE', label: '(372) Estônia', phone: '372' },
    { code: 'MD', label: '(373) República da Moldávia', phone: '373' },
    { code: 'AM', label: '(374) Armênia', phone: '374' },
    { code: 'BY', label: '(375) Bielorrússia', phone: '375' },
    { code: 'AD', label: '(376) Andorra', phone: '376' },
    { code: 'MC', label: '(377) Mônaco', phone: '377' },
    { code: 'SM', label: '(378) San Marino', phone: '378' },
    { code: 'UA', label: '(380) Ucrânia', phone: '380' },
    { code: 'RS', label: '(381) Sérvia', phone: '381' },
    { code: 'ME', label: '(382) Montenegro', phone: '382' },
    { code: 'HR', label: '(385) Croácia', phone: '385' },
    { code: 'SI', label: '(386) Eslovênia', phone: '386' },
    { code: 'BA', label: '(387) Bósnia e Herzegovina', phone: '387' },
    { code: 'MK', label: '(389) Antiga República Jugoslava da Macedônia', phone: '389' },
    { code: 'CZ', label: '(420) Chéquia', phone: '420' },
    { code: 'SK', label: '(421) Eslováquia', phone: '421' },
    { code: 'LI', label: '(423) Liechtenstein', phone: '423' },
    { code: 'FK', label: '(500) Ilhas Malvinas (Falkland)', phone: '500' },
    { code: 'GS', label: '(500) Geórgia do Sul e Ilhas Sandwich do Sul', phone: '500' },
    { code: 'BZ', label: '(501) Belize', phone: '501' },
    { code: 'GT', label: '(502) Guatemala', phone: '502' },
    { code: 'SV', label: '(503) El Salvador', phone: '503' },
    { code: 'HN', label: '(504) Honduras', phone: '504' },
    { code: 'NI', label: '(505) Nicarágua', phone: '505' },
    { code: 'CR', label: '(506) Costa Rica', phone: '506' },
    { code: 'PA', label: '(507) Panamá', phone: '507' },
    { code: 'HT', label: '(509) Haiti', phone: '509' },
    { code: 'GP', label: '(590) Guadalupe', phone: '590' },
    { code: 'BL', label: '(590) São Bartolomeu', phone: '590' },
    { code: 'MF', label: '(590) São Martinho (Parte Francesa)', phone: '590' },
    { code: 'BO', label: '(591) Bolívia (Estado Plurinacional da)', phone: '591' },
    { code: 'GY', label: '(592) Guiana', phone: '592' },
    { code: 'EC', label: '(593) Equador', phone: '593' },
    { code: 'GF', label: '(594) Guiana Francesa', phone: '594' },
    { code: 'PY', label: '(595) Paraguai', phone: '595' },
    { code: 'MQ', label: '(596) Martinica', phone: '596' },
    { code: 'SR', label: '(597) Suriname', phone: '597' },
    { code: 'UY', label: '(598) Uruguai', phone: '598' },
    { code: 'BQ', label: '(599) Bonaire, Santo Eustáquio e Saba', phone: '599' },
    { code: 'CW', label: '(599) Curaçao', phone: '599' },
    { code: 'TL', label: '(670) Timor-Leste', phone: '670' },
    { code: 'AQ', label: '(672) Antártica', phone: '672' },
    { code: 'HM', label: '(672) Ilha Heard e Ilhas McDonald', phone: '672' },
    { code: 'NF', label: '(672) Ilha Norfolk', phone: '672' },
    { code: 'BN', label: '(673) Brunei Darussalam', phone: '673' },
    { code: 'NR', label: '(674) Nauru', phone: '674' },
    { code: 'PG', label: '(675) Papua Nova Guiné', phone: '675' },
    { code: 'TO', label: '(676) Tonga', phone: '676' },
    { code: 'SB', label: '(677) Ilhas Salomão', phone: '677' },
    { code: 'VU', label: '(678) Vanuatu', phone: '678' },
    { code: 'FJ', label: '(679) Fiji', phone: '679' },
    { code: 'PW', label: '(680) Palau', phone: '680' },
    { code: 'WF', label: '(681) Ilhas Wallis e Futuna', phone: '681' },
    { code: 'CK', label: '(682) Ilhas Cook', phone: '682' },
    { code: 'NU', label: '(683) Niué', phone: '683' },
    { code: 'WS', label: '(685) Samoa', phone: '685' },
    { code: 'KI', label: '(686) Quiribati', phone: '686' },
    { code: 'NC', label: '(687) Nova Caledônia', phone: '687' },
    { code: 'TV', label: '(688) Tuvalu', phone: '688' },
    { code: 'PF', label: '(689) Polinésia Francesa', phone: '689' },
    { code: 'TK', label: '(690) Tokelau', phone: '690' },
    { code: 'FM', label: '(691) Micronésia (Estados Federados da)', phone: '691' },
    { code: 'MH', label: '(692) Ilhas Marshall', phone: '692' },
    { code: 'KP', label: '(850) República Popular Democrática da Coreia', phone: '850' },
    { code: 'HK', label: '(852) Região Administrativa Especial de Hong Kong da China', phone: '852' },
    { code: 'MO', label: '(853) Região Administrativa Especial de Macau da China', phone: '853' },
    { code: 'KH', label: '(855) Camboja', phone: '855' },
    { code: 'LA', label: '(856) República Popular Democrática do Laos', phone: '856' },
    { code: 'PN', label: '(870) Pitcairn', phone: '870' },
    { code: 'BD', label: '(880) Bangladesh', phone: '880' },
    { code: 'TW', label: '(886) Taiwan', phone: '886' },
    { code: 'MV', label: '(960) Maldivas', phone: '960' },
    { code: 'LB', label: '(961) Líbano', phone: '961' },
    { code: 'JO', label: '(962) Jordânia', phone: '962' },
    { code: 'SY', label: '(963) República Árabe da Síria', phone: '963' },
    { code: 'IQ', label: '(964) Iraque', phone: '964' },
    { code: 'KW', label: '(965) Kuwait', phone: '965' },
    { code: 'SA', label: '(966) Arábia Saudita', phone: '966' },
    { code: 'YE', label: '(967) Iémen', phone: '967' },
    { code: 'OM', label: '(968) Omã', phone: '968' },
    { code: 'PS', label: '(970) Estado da Palestina', phone: '970' },
    { code: 'AE', label: '(971) Emirados Árabes Unidos', phone: '971' },
    { code: 'IL', label: '(972) Israel', phone: '972' },
    { code: 'BH', label: '(973) Bahrain', phone: '973' },
    { code: 'QA', label: '(974) Catar', phone: '974' },
    { code: 'BT', label: '(975) Butão', phone: '975' },
    { code: 'MN', label: '(976) Mongólia', phone: '976' },
    { code: 'NP', label: '(977) Nepal', phone: '977' },
    { code: 'TJ', label: '(992) Tajiquistão', phone: '992' },
    { code: 'TM', label: '(993) Turcomenistão', phone: '993' },
    { code: 'AZ', label: '(994) Azerbaijão', phone: '994' },
    { code: 'GE', label: '(995) Geórgia', phone: '995' },
    { code: 'KG', label: '(996) Quirguistão', phone: '996' },
    { code: 'UZ', label: '(998) Uzbequistão', phone: '998' },
    { code: 'BS', label: '(1-242) Bahamas', phone: '1-242' },
    { code: 'BB', label: '(1-246) Barbados', phone: '1-246' },
    { code: 'AI', label: '(1-264) Anguilla', phone: '1-264' },
    { code: 'AG', label: '(1-268) Antígua e Barbuda', phone: '1-268' },
    { code: 'VG', label: '(1-284) Ilhas Virgens Britânicas', phone: '1-284' },
    { code: 'VI', label: '(1-340) Ilhas Virgens dos Estados Unidos', phone: '1-340' },
    { code: 'KY', label: '(1-345) Ilhas Cayman', phone: '1-345' },
    { code: 'BM', label: '(1-441) Bermudas', phone: '1-441' },
    { code: 'GD', label: '(1-473) Granada', phone: '1-473' },
    { code: 'TC', label: '(1-649) Ilhas Turks e Caicos', phone: '1-649' },
    { code: 'MS', label: '(1-664) Montserrat', phone: '1-664' },
    { code: 'MP', label: '(1-670) Ilhas Marianas do Norte', phone: '1-670' },
    { code: 'GU', label: '(1-671) Guam', phone: '1-671' },
    { code: 'AS', label: '(1-684) Samoa Americana', phone: '1-684' },
    { code: 'SX', label: '(1-721) São Martinho (Parte Holandesa)', phone: '1-721' },
    { code: 'LC', label: '(1-758) Santa Lúcia', phone: '1-758' },
    { code: 'DM', label: '(1-767) Dominica', phone: '1-767' },
    { code: 'VC', label: '(1-784) São Vicente e Granadinas', phone: '1-784' },
    { code: 'DO', label: '(1-809) República Dominicana', phone: '1-809' },
    { code: 'DO', label: '(1-829) República Dominicana', phone: '1-829' },
    { code: 'DO', label: '(1-849) República Dominicana', phone: '1-849' },
    { code: 'TT', label: '(1-868) Trinidad e Tobago', phone: '1-868' },
    { code: 'KN', label: '(1-869) São Cristóvão e Nevis', phone: '1-869' },
    { code: 'JM', label: '(1-876) Jamaica', phone: '1-876' },
    { code: 'VA', label: '(39-06) Santa Sé (Cidade do Vaticano)', phone: '39-06' },
  ];

export default CountrySelect

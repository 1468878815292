import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { createTheme, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles'
import { FilterContextProvider } from './context/filter'

import theme from './styles/themes'
import { default as GlobalStyles } from './styles/globals'

import App from './App'

const material_theme = createTheme({
  palette: {
    primary: {
      main: '#651fff',
    },
    secondary: {
      main: '#ffff00',
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ThemeProvider theme={theme}>
    <MaterialThemeProvider theme={material_theme}>
      <FilterContextProvider>
        <GlobalStyles />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </FilterContextProvider>
    </MaterialThemeProvider>
  </ThemeProvider>
)

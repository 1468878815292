import { FC, createContext, useContext, useEffect, useState } from 'react'

import { DataContextType } from './types'

import { request } from '../utils/fetcher'

import { filterDefaultValues } from '../constants/filter'

export const DataContext = createContext({} as DataContextType)

let chatsInterval: any
const INITIAL_LIMIT = 10
const INTERVAL_TIME = 5000
let updating = false
let limit = INITIAL_LIMIT

export const DataContextProvider: FC<{ children: any }> = ({ children }) => {
  const [userPreferenceAudioSpeed, setUserPreferenceAudioSpeed] = useState(
    localStorage.userPreferenceAudioSpeed ? Number(localStorage.userPreferenceAudioSpeed) : 1
  )

  // ChatList
  const [chatsList, setChatsList] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [oldChatsLoading, setOldChatsLoading] = useState(false)
  const [newChatsLoading, setNewChatsLoading] = useState(false)
  const [post, setPost] = useState<any>(filterDefaultValues)

  useEffect(() => {
    return () => {
      clearChatsInterval()
    }
  }, [])

  useEffect(() => {
    refreshChatList(false)
  }, [post])

  const refreshChatList = (silence: boolean) => {
    clearChatsInterval()

    setOldChatsLoading(false)
    setNewChatsLoading(silence)
    setLoading(!silence)

    updating = false
    limit = INITIAL_LIMIT

    updateChatList(limit, post, silence)
  }

  const updateChatList = (limit: number, post: any, silence: boolean) => {
    getAllChats(limit, post)
    createChatsInterval(limit, post, silence)
  }

  const getAllChats = async (limit: number, post: any) => {
    if (!updating) {
      updating = true

      const filters = new URLSearchParams(post).toString() + '&limit=' + limit
      const data = await request('chats', {}, {}, { method: 'GET', querystring: filters })
      data.success && setChatsList((prev: any) => [...data.chats])
      setLoading(false)
      setNewChatsLoading(false)
      setOldChatsLoading(false)

      updating = false
    }
  }

  const createChatsInterval = (limit: number, post: any, silence: boolean) => {
    setLoading(!silence)

    chatsInterval = setInterval(() => {
      setNewChatsLoading(true)
      getAllChats(limit, post)
    }, INTERVAL_TIME)
  }

  const clearChatsInterval = () => {
    clearInterval(chatsInterval)
  }

  const getUserCompanies = async () => {
    const data = await request('companies', {}, {}, { method: 'GET' })

    if (data.success) return data.companies
    else return []
  }

  const handleChange = (e: any) => {
    setPost({ ...post, [e.target.name]: e.target.value })
  }

  const handleToggle = (e: any) => {
    setPost({ ...post, [e.target.name]: e.target.checked })
  }

  const handleClear = () => {
    setPost(filterDefaultValues)
  }

  const handleOldChats = () => {
    setOldChatsLoading(true)
  }

  const resetLimit = () => {
    setTimeout(() => {
      limit = INITIAL_LIMIT
    }, 1000 * 60 * 5)
  }

  const incrementLimit = () => {
    setOldChatsLoading(true)
    clearChatsInterval()
    limit += INITIAL_LIMIT
    createChatsInterval(limit, post, false)
  }

  const handleChangeUserPreferenceAudioSpeed = (speed: number) => {
    setUserPreferenceAudioSpeed(speed)
    localStorage.setItem('userPreferenceAudioSpeed', `${speed}`)
  }

  return (
    <DataContext.Provider
      value={{
        getUserCompanies,
        clearChatsInterval,
        post,
        loading,
        chatsList,
        handleChange,
        handleClear,
        handleToggle,
        oldChatsLoading,
        newChatsLoading,
        handleOldChats,
        incrementLimit,
        resetLimit,
        refreshChatList,
        userPreferenceAudioSpeed,
        handleChangeUserPreferenceAudioSpeed,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

import { FC, useState, useEffect } from 'react'
import {
  Alert,
  Button,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { request } from '../../utils/fetcher'

const Departments: FC = () => {
  const week = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom']

  const defaultPost = {
    action: 'create',
    id: '',
    device_id: '',
    name: '',
    activate_access: false,
  }

  const [tab, setTab] = useState(0)
  const [post, setPost] = useState(defaultPost as any)
  const [error, setError] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [departments, setDepartments] = useState([])
  const [devices, setDevices] = useState([])
  const [deleteDepartmentId, setDeleteDepartmentId] = useState(0)
  const [confirmDelete, setConfirmDelete] = useState(false)

  useEffect(() => {
    loadAllDevices()

    if (tab === 0) {
      setDepartments([])
      handleClear()
      loadAllDepartments()
    }
  }, [tab])

  const loadAllDevices = async () => {
    const data = await request(`company/get-devices`, {}, {}, { method: 'GET' })
    setDevices(data.devices)
  }

  const loadDepartmentAccessTime = async (id: number) => {
    let newPost = {} as any
    const data = await request(`company/department-access-time/${id}`, {}, {}, { method: 'GET' })

    if (data.success) {
      data.access_time.forEach((time: any) => {
        let w = null

        switch (time.access_time_work_day) {
          case 1:
            w = 'Seg'
            break
          case 2:
            w = 'Ter'
            break
          case 3:
            w = 'Qua'
            break
          case 4:
            w = 'Qui'
            break
          case 5:
            w = 'Sex'
            break
          case 6:
            w = 'Sab'
            break
          case 7:
            w = 'Dom'
            break
          default:
            break
        }

        if (w) {
          newPost[`activate_access_${w}`] = true
          newPost[`from_${w}`] = time.access_time_hour_start
          newPost[`to_${w}`] = time.access_time_hour_end
        }
      })
    }

    return newPost
  }

  const loadAllDepartments = async () => {
    setLoading(true)
    const data = await request(`company/get-all-company-departments/`, {}, {}, { method: 'GET' })

    if (data.success && data.departments.length > 0) setDepartments(data.departments)
    setLoading(false)
  }

  const onChange = (e: any) => {
    setPost({ ...post, [e.target.name]: e.target.value })
  }

  const handleClear = () => {
    setPost(defaultPost as any)
    setError('')
    setSuccessMsg('')
  }

  const handleSubmit = async () => {
    setError('')
    setSuccessMsg('')

    if (post.name === '') setError('Preencha o Nome.')
    else if (post.device_id === '') setError('Selecione o número de telefone.')
    else {
      setLoading(true)

      const resp = await request(`company/department`, { department: post })

      if (resp.success) {
        setSuccessMsg('Departamento salvo com sucesso!')
      } else {
        setError('Não foi possível salvar o departamento, tente novamente.')
      }

      setLoading(false)
    }
  }

  const handleDelete = (department_id: number) => {
    setError('')
    setDeleteDepartmentId(department_id)
    setConfirmDelete(true)
  }

  const deleteDepartment = async (department_id: number) => {
    setConfirmDelete(false)
    setLoading(true)

    const resp = await request(`company/delete-department/${department_id}`, {})

    if (resp.success) {
      loadAllDepartments()
    } else {
      setError(resp.message)
      setLoading(false)
    }
  }

  const handleEdit = async (department: any) => {
    setTab(1)
    setLoading(true)
    const access_time = await loadDepartmentAccessTime(department.value as number)
    setPost({
      ...access_time,
      id: department.value,
      name: department.name,
      action: 'update',
      device_id: department.device_id,
      activate_access: department.restrict_access === 1 ? true : false,
    })
    setLoading(false)
  }

  return (
    <div>
      <Modal
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              minWidth: 'auto',
              maxWidth: '400px',
              maxHeight: '90%',
              background: '#fff',
              borderRadius: '8px',
              position: 'relative',
              padding: '40px',
              overflowY: 'auto',
            }}
          >
            <div style={{ position: 'absolute', right: 0, top: 0 }}>
              <IconButton onClick={() => setConfirmDelete(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            Tem certeza que deseja remover este departamento?
            <div
              style={{
                margin: '15px 0 0 0',
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <Button
                onClick={() => deleteDepartment(deleteDepartmentId)}
                variant="contained"
                size="small"
                sx={{ width: 'auto' }}
              >
                Remover
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {tab === 0 && (
        <>
          <div style={{ textAlign: 'right', marginBottom: 20 }}>
            <Button
              onClick={() => {
                setTab(1)
                setPost(defaultPost)
              }}
              variant="contained"
              size="small"
              sx={{ width: 'auto' }}
            >
              Adicionar Departamento &nbsp; <AddCircleOutlineIcon fontSize={'small'} />
            </Button>
          </div>
          <div>
            {error !== '' && <Alert severity="error">{error}</Alert>}
            {loading && <LinearProgress />}
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width="40%"> Nome </TableCell>
                    <TableCell width="40%"> Telefone </TableCell>
                    <TableCell width="20%"> &nbsp; </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {departments.map((d: any, i: number) => {
                    const device: any = devices.filter((device: any) => device.value === d.device_id)
                    return (
                      <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{d.name}</TableCell>
                        <TableCell>{device && device.length > 0 ? device[0].name : ''}</TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                          <Fab onClick={() => handleDelete(d.value)} size="small" color="error">
                            <DeleteIcon sx={{ fontSize: 14 }} />
                          </Fab>
                          &nbsp;&nbsp;
                          <Fab onClick={() => handleEdit(d)} size="small" color="primary">
                            <EditIcon sx={{ fontSize: 14 }} />
                          </Fab>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
      {tab === 1 && (
        <>
          <div style={{ textAlign: 'right' }}>
            <IconButton onClick={() => setTab(0)}>
              <CloseIcon />
            </IconButton>
          </div>

          <FormGroup>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    name="name"
                    size="small"
                    label="Nome *"
                    value={post['name']}
                    autoComplete="off"
                    onChange={onChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    name="device_id"
                    size="small"
                    label={`Número do Telefone *`}
                    value={post['device_id']}
                    select
                    onChange={onChange}
                  >
                    {devices.map((item: any) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              {post.id > 0 && (
                <Grid item xs={12}>
                  <div style={{ flexDirection: 'row', display: 'flex', gap: '20px', marginBottom: '20px' }}>
                    <h4
                      style={{
                        margin: '0 0 3px 0',
                        fontSize: 16,
                        fontWeight: '700',
                        color: '#000',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      Restringir Horário URA
                    </h4>
                    <FormControlLabel
                      name="activate_access"
                      onChange={(e: any) => {
                        setPost({ ...post, [e.target.name]: e.target.checked })
                      }}
                      control={<Switch checked={post['activate_access']} size="small" />}
                      label={post['activate_access'] ? 'Ativo' : 'Inativo'}
                    />
                  </div>

                  {post['activate_access'] && (
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell> &nbsp; </TableCell>
                            {week.map((w, i) => (
                              <TableCell key={i}>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                  {w}
                                  <FormControlLabel
                                    label=""
                                    name={`activate_access_${w}`}
                                    onChange={(e: any) => {
                                      setPost({ ...post, [e.target.name]: e.target.checked })
                                    }}
                                    control={<Switch checked={post[`activate_access_${w}`]} size="small" />}
                                  />
                                </div>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>De</TableCell>
                            {week.map((w, i) => (
                              <TableCell key={`from_${w}`}>
                                <FormControl fullWidth size="small">
                                  <TextField
                                    name={`from_${w}`}
                                    size="small"
                                    value={post[`from_${w}`]}
                                    onChange={onChange}
                                    type="time"
                                    style={{ width: 110 }}
                                    disabled={!post[`activate_access_${w}`]}
                                  />
                                </FormControl>
                              </TableCell>
                            ))}
                          </TableRow>
                          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>Até</TableCell>
                            {week.map((w, i) => (
                              <TableCell key={`to_${w}`}>
                                <FormControl fullWidth size="small">
                                  <TextField
                                    name={`to_${w}`}
                                    size="small"
                                    value={post[`to_${w}`]}
                                    onChange={onChange}
                                    type="time"
                                    style={{ width: 110 }}
                                    disabled={!post[`activate_access_${w}`]}
                                  />
                                </FormControl>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              )}
            </Grid>
          </FormGroup>

          <div style={{ margin: '40px 0', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                handleClear()
              }}
              size="small"
              sx={{ width: 'auto' }}
            >
              Limpar
            </Button>

            <Button
              onClick={() => {
                handleSubmit()
              }}
              disabled={loading}
              variant="contained"
              size="small"
              sx={{ width: 'auto' }}
            >
              Salvar
            </Button>
          </div>
          {loading && <LinearProgress />}
          {error !== '' && <Alert severity="error">{error}</Alert>}
          {successMsg !== '' && (
            <Alert severity="success">
              <div dangerouslySetInnerHTML={{ __html: successMsg }} />
            </Alert>
          )}
        </>
      )}
    </div>
  )
}

export default Departments

import { FC, useEffect, useState } from 'react'

import Image from './Image'
import Video from './Video'
import AudioComponent from './Audio'
import Document from './Document'
import { getExtension } from '../../../../../../utils/files'

const Midia: FC<{ src: string; forceDocument?: boolean; size?: { width: number; height: number } }> = ({
  src,
  forceDocument,
  size,
}) => {
  const [ext, setExt] = useState('')
  const [expired, setExpired] = useState(false)

  useEffect(() => {
    checkIfFileExists(src)
  }, [src])

  const checkIfFileExists = (src: string) => {
    try {
      fetch(src, { method: 'HEAD' }).then((res) => {
        if (res.ok) {
          setExt(getExtension(src) || '')
        } else {
          setExpired(true)
        }
      })
    } catch (error) {
      setExpired(true)
    }
  }

  const getElement = (src: string, extension: string, forceDocument: boolean) => {
    if (forceDocument) return <Document src={src} />

    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'webp':
        return <Image src={src} size={size} />
      case '3gp':
      case 'mp4':
        return <Video src={src} extension={extension} size={size} />
      case 'acc':
      case 'amr':
      case 'mpeg':
      case 'ogg':
      case 'wav':
      case 'mp3':
      case 'webm':
        return <AudioComponent src={src} extension={extension} />
      default:
        return <Document src={src} size={size} />
    }
  }

  if (ext !== '') return getElement(src, ext, forceDocument || false)
  if (expired)
    return (
      <span style={{ fontSize: '12px', fontStyle: 'italic' }}>Arquivo expirou, acesse através do seu dispositivo.</span>
    )

  return null
}

export default Midia

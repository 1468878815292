import { FC } from 'react'

import PhoneIcon from '@mui/icons-material/Phone'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import PersonIcon from '@mui/icons-material/Person'
import LocationOnIcon from '@mui/icons-material/LocationOn'

const VCard: FC<{ data: string }> = ({ data }) => {
  const vCard = require('vcard-parser')

  const info = vCard.parse(data)

  return (
    <div
      style={{
        padding: '10px 20px 10px 10px',
        margin: '5px 0',
        width: '300px',
        background: 'rgba(255, 255, 255, 0.8)',
        fontSize: 14,
        display: 'flex',
        gap: '7px',
      }}
    >
      <PersonIcon sx={{ fontSize: 16, color: '#651fff' }} />
      <div>
        <h4 style={{ fontSize: 14, color: '#651fff' }}>{info.fn[0].value ? info.fn[0].value : 'Contato'}</h4>
        <div
          style={{
            margin: '3px 0',
            display: 'flex',
            gap: '5px',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <PhoneIcon sx={{ fontSize: 14, marginTop: '2px', color: '#999' }} />

          <div style={{ paddingTop: '2px' }}>
            {info.tel
              ? info.tel.map((t: any) => {
                  return <p key={t.value}>{t.value}</p>
                })
              : 'Contato sem telefone'}
          </div>
        </div>

        {info.email && (
          <div
            style={{
              margin: '3px 0',
              display: 'flex',
              gap: '5px',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <AlternateEmailIcon sx={{ fontSize: 14, marginTop: '2px', color: '#999' }} />

            <div style={{ paddingTop: '2px' }}>
              {info.email?.map((t: any) => {
                return <p key={t.value}>{t.value}</p>
              })}
            </div>
          </div>
        )}
        {info.adr && (
          <div
            style={{
              margin: '3px 0',
              display: 'flex',
              gap: '5px',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <LocationOnIcon sx={{ fontSize: 14, marginTop: '2px', color: '#999' }} />

            <div style={{ paddingTop: '2px' }}>
              {info.adr?.map((t: any, i: number) => {
                return <p key={i}>{t.value.map((v: any) => `${v} `)}</p>
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default VCard

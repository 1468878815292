import { FC, useContext, useState } from 'react'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { request } from '../../../../../utils/fetcher'
import { DataContext } from '../../../../../context'

function TabPanel(props: any) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  )
}

const TransferForm: FC<{ clientId: any; handleClose: () => void; setores: any; users: any }> = ({
  clientId,
  handleClose,
  setores,
  users,
}) => {
  const [setor, setSetor] = useState(0)
  const [user, setUser] = useState(0)
  const [tab, setTab] = useState(0)
  const { refreshChatList } = useContext(DataContext)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const handleSave = async () => {
    if (tab === 0 && setor && setor > 0) {
      await request(`messages/${clientId}/transfer-setor`, { setor })
    } else if (tab === 1 && user && user > 0) {
      await request(`messages/${clientId}/transfer-user`, { user })
    }

    refreshChatList(true)
    handleClose()
  }

  return (
    <>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <Tabs value={tab} onChange={handleChange} sx={{ marginBottom: '5px' }}>
        <Tab label="Departamento" />
        <Tab label="Usuário" />
      </Tabs>

      <TabPanel value={tab} index={0}>
        <FormControl fullWidth>
          <Select
            value={setor}
            onChange={(e) => {
              setSetor(parseInt(`${e.target.value}`))
            }}
            displayEmpty
          >
            <MenuItem key={''} value={''}>
              &nbsp;
            </MenuItem>
            {setores.map((item: any) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <FormControl fullWidth>
          <Select
            value={user}
            onChange={(e) => {
              setUser(parseInt(`${e.target.value}`))
            }}
            displayEmpty
          >
            <MenuItem key={''} value={''}>
              &nbsp;
            </MenuItem>
            {users.map((item: any) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TabPanel>

      <div style={{ margin: '15px 0 0 0', textAlign: 'right' }}>
        <Button
          onClick={() => {
            handleSave()
          }}
          variant="contained"
          size="small"
          sx={{ width: 'auto' }}
        >
          Transferir &nbsp; <KeyboardDoubleArrowRightIcon />
        </Button>
      </div>
    </>
  )
}

export default TransferForm

import { FC, useContext, useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/Close'

import Layout from '../../components/Layout'
import theme from '../../styles/themes'
import { request } from '../../utils/fetcher'
import TableFixedMessages from '../../components/TableFixedMessages'
import { FilterContext } from '../../context/filter'

let deleteOnDialog = () => {}

const DefaultMessages: FC = () => {
  const defaultPost = {
    titulo: '',
    mensagem: '',
    tag: '',
  }

  const { filtersOptions } = useContext(FilterContext)
  const [loading, setLoading] = useState(true)
  const [messages, setMessages] = useState<any[]>([])
  const [usersShare, setUsersShare] = useState<any[]>([])
  const [post, setPost] = useState(defaultPost as any)
  const [confirmDelete, setConfirmDelete] = useState('')
  const [error, setError] = useState('')
  const [filterTag, setFilterTag] = useState(0)

  useEffect(() => {
    loadAllDefaultMessages()
    loadAllUsers()
  }, [])

  const loadAllUsers = async () => {
    const data = await request(`company/all-users/`, {}, {}, { method: 'GET' })

    if (data.success && data.users.length > 0) setUsersShare(data.users)
  }

  const loadAllDefaultMessages = async () => {
    const data = await request(`company/default-messages`, {}, {}, { method: 'GET' })

    if (data.success && data.messages.length > 0) setMessages(data.messages)

    setLoading(false)
  }

  const handleClear = () => {
    setPost(defaultPost as any)
    setError('')
  }

  const handleSubmit = async () => {
    if (post.titulo === '') setError('Preencha o Título.')
    else if (post.mensagem === '') setError('Preencha a Mensagem.')
    else {
      setLoading(true)

      await request(`company/create-default-message`, {
        title: post.titulo,
        message: post.mensagem,
        tag: post.tag,
      })

      setLoading(false)
      handleClear()
      loadAllDefaultMessages()
    }
  }

  const handleDelete = async (id: any) => {
    setConfirmDelete(`Tem certeza que deseja remover esta mensagem?`)

    deleteOnDialog = async () => {
      setConfirmDelete('')
      setLoading(true)
      await request(`company/delete-message/${id}`)
      loadAllDefaultMessages()
    }
  }

  const onChange = (e: any) => {
    setPost({ ...post, [e.target.name]: e.target.value })
    setError('')
  }

  return (
    <div
      style={{
        overflow: 'auto',
      }}
    >
      <Modal
        open={confirmDelete !== ''}
        onClose={() => setConfirmDelete('')}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              minWidth: 'auto',
              maxWidth: '400px',
              maxHeight: '90%',
              background: '#fff',
              borderRadius: '8px',
              position: 'relative',
              padding: '40px',
              overflowY: 'auto',
            }}
          >
            <div style={{ position: 'absolute', right: 0, top: 0 }}>
              <IconButton onClick={() => setConfirmDelete('')}>
                <CloseIcon />
              </IconButton>
            </div>

            {confirmDelete}

            <div
              style={{
                margin: '15px 0 0 0',
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <Button onClick={() => deleteOnDialog()} variant="contained" size="small" sx={{ width: 'auto' }}>
                Deletar
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Layout>
        <Box
          sx={{
            maxWidth: '100%',
            margin: '20px',
          }}
        >
          <h4
            style={{
              padding: '0 20px',
              margin: '0 0 20px 0',
              fontSize: 16,
              fontWeight: '400',
              color: theme.colors.primary,
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <MarkEmailReadIcon /> Nova Mensagem Padrão
          </h4>

          <FormGroup>
            <Grid container spacing={2} sx={{ padding: '0 20px' }}>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <TextField name="titulo" size="small" label="Título *" value={post['titulo']} onChange={onChange} />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small" style={{ position: 'relative' }}>
                  <span
                    style={{
                      position: 'absolute',
                      zIndex: 2,
                      right: 30,
                      top: 10,
                    }}
                    title="A tag selecionada será automaticamente atribuída ao contato sempre que o arquivo for enviado. (Opcional)"
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </span>
                  <InputLabel id="select-tag">Tag</InputLabel>
                  <Select
                    labelId="select-tag"
                    name="tag"
                    label="Tag"
                    value={post['tag']}
                    input={<OutlinedInput label="Tag" />}
                    onChange={onChange}
                    displayEmpty
                  >
                    <MenuItem value=""> &nbsp; </MenuItem>
                    {filtersOptions.tags.map((item: any) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <TextField
                    label="Mensagem *"
                    name="mensagem"
                    value={post['mensagem']}
                    onChange={onChange}
                    multiline
                    minRows={5}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </FormGroup>

          {error !== '' && (
            <div style={{ padding: '20px' }}>
              <Alert severity="error">{error}</Alert>
            </div>
          )}

          <div style={{ margin: '40px 20px', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                handleClear()
              }}
              size="small"
              sx={{ width: 'auto' }}
            >
              Limpar
            </Button>

            <Button
              onClick={() => {
                handleSubmit()
              }}
              variant="contained"
              size="small"
              sx={{ width: 'auto' }}
            >
              Salvar
            </Button>
          </div>

          <Box
            sx={{
              margin: '20px',
              paddingBottom: '40px',
            }}
          >
            {loading && <LinearProgress />}
            {!loading && messages.length > 0 && (
              <>
                <div style={{ width: '50dvw', margin: '0 0 15px 0' }}>
                  <FormControl fullWidth size="small" style={{ position: 'relative' }}>
                    <InputLabel id="select-tag">Tag</InputLabel>
                    <Select
                      labelId="select-tag"
                      name="tag"
                      label="Tag"
                      value={filterTag}
                      input={<OutlinedInput label="Tag" />}
                      onChange={(e) => setFilterTag(Number(e.target.value))}
                      displayEmpty
                    >
                      <MenuItem value=""> &nbsp; </MenuItem>
                      {filtersOptions.tags.map((item: any) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <TableFixedMessages
                  messages={messages}
                  handleDelete={handleDelete}
                  filterTag={filterTag}
                  showTag={true}
                  users={usersShare}
                  reload={() => {
                    setLoading(true)
                    loadAllDefaultMessages()
                  }}
                />
              </>
            )}
          </Box>
        </Box>
      </Layout>
    </div>
  )
}

export default DefaultMessages

import { FC } from 'react'

import Text from './Text'
import Midia from './Midia'
import Map from './Map'
import VCard from './VCard'
import Sticker from './Sticker'

const Message: FC<{ type: number; children: any; size?: { width: number; height: number } }> = ({
  type,
  children,
  size,
}) => {
  const getElement = (type: number) => {
    switch (type) {
      case 1:
        return <Midia src={children} size={size} />
      case 2:
        return <Map location={children} />
      case 3:
        return <VCard data={children} />
      case 4:
        return <Midia src={children} size={size} forceDocument={true} />
      case 5:
        return <Sticker src={children} />
      default:
        return <Text>{children}</Text>
    }
  }

  return <div style={{ marginBottom: '5px' }}>{getElement(type)}</div>
}

export default Message

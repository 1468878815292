import { FC, useState } from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material'

import Layout from '../../components/Layout'
import theme from '../../styles/themes'

import SettingsIcon from '@mui/icons-material/Settings'
import Users from './Users'
import Departments from './Departments'
import Ura from './Ura'
import Tags from './Tags'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const Settings: FC = () => {
  const [tab, setTab] = useState(0)

  const handleChangeTab = (e: any, value: number) => {
    setTab(value)
  }

  return (
    <div
      style={{
        overflow: 'auto',
      }}
    >
      <Layout>
        <Box
          sx={{
            maxWidth: '100%',
            margin: '20px',
          }}
        >
          <h4
            style={{
              padding: '0 20px',
              margin: '0 0 20px 0',
              fontSize: 16,
              fontWeight: '400',
              color: theme.colors.primary,
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <SettingsIcon /> Configurações
          </h4>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label="Usuários" />
              <Tab label="Departamentos" />
              <Tab label="Tags" />
              <Tab label="Ura" />
            </Tabs>
          </Box>
          <CustomTabPanel value={tab} index={0}>
            <Users />
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={1}>
            <Departments />
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={2}>
            <Tags />
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={3}>
            <Ura />
          </CustomTabPanel>
        </Box>
      </Layout>
    </div>
  )
}

export default Settings

import { FC } from 'react'

const Text: FC<{ children: any }> = ({ children }) => {
  const linkRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g

  const replacer = (matched: string) => {
    let withProtocol = matched

    if (!withProtocol.startsWith('http')) {
      withProtocol = 'http://' + matched
    }

    const newStr = `<a href="${withProtocol}" target="_blank">${matched}</a>`

    return newStr
  }

  let message = children.replace(linkRegex, replacer)

  const formatter = (texto: string) => {
    var formatacoes = [
      { regex: /\*([^*]+)\*/g, substituto: '<strong>$1</strong>' },
      { regex: /_([^_]+)_/g, substituto: '<em>$1</em>' },
      { regex: /~([^~]+)~/g, substituto: '<del>$1</del>' },
    ]

    for (var i = 0; i < formatacoes.length; i++) {
      var formatacao = formatacoes[i]
      texto = texto.replace(formatacao.regex, formatacao.substituto)
    }

    texto = texto.replace(/\n/g, '<br>')

    return texto
  }

  return <p style={{ fontSize: '0.8rem' }} dangerouslySetInnerHTML={{ __html: formatter(message) }} />
}

export default Text

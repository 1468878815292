import { FC } from 'react'

const Image: FC<{ src: string, size?: { width: number, height: number } }> = ({ src, size }) => {
    const width = size?.width ? size.width : 300
    const height = size?.height ? size.height : 150

    return ( 
        <div style={{ width: `${width}px`, height: `${height}px` }}>
            <a href={src} target='_blank'>
                <img src={src} style={{ margin: '5px 0', boxShadow: '1px 1px 5px #666', width: '100%', height: '100%', objectFit: 'cover' }} />
            </a>
        </div>
    )
}

export default Image
import {
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  TextField,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Button,
  IconButton,
} from '@mui/material'
import { FC, useContext, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'

import { request } from '../../utils/fetcher'
import { FilterContext } from '../../context/filter'

const TableFixedMessages: FC<{
  messages: any[]
  handleDelete?: any
  handleSend?: any
  filterTag?: number
  showTag?: boolean
  size?: string
  users: any[]
  reload: () => void
}> = ({ messages, handleDelete, handleSend, filterTag, showTag, reload, users }) => {
  const { filtersOptions } = useContext(FilterContext)
  const [query, setQuery] = useState('')
  const [shareIds, setShareIds] = useState<string[]>([])
  const [shareUsers, setShareUsers] = useState<any[]>([])

  const getTagName = (tag: any) => {
    const filtered = filtersOptions.tags.filter((t: any) => Number(t.value) === Number(tag))

    if (filtered.length === 0) return 'sem tag'
    else return filtered[0].name
  }

  const handleUpdate = async ({ id, title, content }: { id: number; title?: string; content?: string }) => {
    await request(`company/default-messages/${id}`, {
      title: title ? title : '',
      content: content ? content : '',
    })
  }

  const handleChangeCheckbox = (e: any) => {
    const id = e.target.value as string
    if (e.target.checked) {
      if (!shareIds.includes(id)) setShareIds([...shareIds, id])
    } else {
      setShareIds(shareIds.filter((item: string) => item !== id))
    }
  }

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      setShareIds(filteredMessages.map((item) => item.FixedMSG_id.toString()))
    } else {
      setShareIds([])
    }
  }

  const handleShare = async () => {
    await request(`company/share-default-messages/`, {
      messages: shareIds,
      users: shareUsers,
    })

    reload()
  }

  let filteredMessages = messages
    .filter((m: any) => {
      if (filterTag && filterTag > 0) {
        return m.FixedMSG_TagID === filterTag
      }

      return true
    })
    .filter((m: any) => {
      if (query.trim() !== '') {
        return (
          m.FixedMSG_Label.toLowerCase().includes(query.toLowerCase().trim()) ||
          m.FixedMSG_FullTXT.toLowerCase().includes(query.trim().toLowerCase())
        )
      }

      return true
    })

  const handleReorder = async (messageId: any, order: number) => {
    await request(`company/reorder-message/${messageId}`, {
      order,
    })

    if (order === filteredMessages.length - 1) reload()
  }

  const handleReorderIndexes = async (index: number, nextValue: number) => {
    let newArr = filteredMessages
    newArr.splice(nextValue, 0, newArr.splice(index, 1)[0])

    newArr.forEach((item, i) => {
      handleReorder(item.FixedMSG_id, i)
    })
  }

  return (
    <>
      <div style={{ width: '50dvw', margin: '0 0 15px 0' }}>
        <FormControl fullWidth size="small" style={{ position: 'relative' }}>
          <TextField
            id="text-label"
            name="query"
            label="Pesquisar"
            variant="outlined"
            size="small"
            value={query}
            onChange={(e: any) => setQuery(e.target.value)}
          />
        </FormControl>
      </div>

      {filteredMessages.length === 0 && <div style={{ padding: '20px', fontSize: 14 }}>Nenhum arquivo encontrado.</div>}

      {filteredMessages.length > 0 && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="10">
                  <Checkbox onChange={handleCheckAll} />
                </TableCell>
                <TableCell width="10"> &nbsp; </TableCell>
                <TableCell width="27%">Título</TableCell>
                <TableCell width="53%">Mensagem</TableCell>
                <TableCell width="10"> &nbsp; </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredMessages.map((m: any, i: number) => {
                const {
                  FixedMSG_id: id,
                  FixedMSG_Label: title,
                  FixedMSG_FullTXT: message,
                  FixedMSG_TagID: tag,
                  FixedMSG_createdDate: created_at,
                } = m

                return (
                  <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      <Checkbox
                        value={id}
                        name="share"
                        onChange={handleChangeCheckbox}
                        checked={shareIds.includes(id.toString())}
                      />
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {i > 0 && (
                          <IconButton aria-label="delete" size="small" onClick={() => handleReorderIndexes(i, i - 1)}>
                            <KeyboardDoubleArrowUpIcon fontSize="inherit" />
                          </IconButton>
                        )}
                        {i < filteredMessages.length - 1 && (
                          <IconButton aria-label="delete" size="small" onClick={() => handleReorderIndexes(i, i + 1)}>
                            <KeyboardDoubleArrowDownIcon fontSize="inherit" />
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="titulo"
                        size="small"
                        sx={{ width: '100%' }}
                        defaultValue={title}
                        title={title}
                        onBlur={(e) => handleUpdate({ id, title: e.target.value })}
                      />

                      {showTag && (
                        <>
                          <br />
                          <span style={{ fontSize: '12', fontStyle: 'italic', paddingTop: 5 }}>{getTagName(tag)}</span>
                        </>
                      )}
                      <br />
                      <span style={{ fontSize: 10, color: '#666', paddingTop: 3 }}>{created_at}</span>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="mensagem"
                        defaultValue={message}
                        title={message}
                        multiline
                        style={{ width: '100%' }}
                        onBlur={(e) => handleUpdate({ id, content: e.target.value })}
                      />
                    </TableCell>
                    <TableCell>
                      {handleDelete && (
                        <Fab onClick={() => handleDelete(id)} size="small" color="error">
                          <DeleteIcon />
                        </Fab>
                      )}
                      {handleSend && (
                        <Fab onClick={() => handleSend(id)} size="small" color="primary">
                          <SendIcon sx={{ fontSize: 12 }} />
                        </Fab>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {filteredMessages.length > 0 && (
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          <div style={{ margin: '20px 0', width: '40%' }}>
            <FormControl fullWidth size="small">
              <InputLabel id="select-user">Usuários</InputLabel>
              <Select
                labelId="select-user"
                multiple
                name="user"
                label="Usuários"
                value={shareUsers || []}
                onChange={(e) => setShareUsers(e.target.value as any)}
                renderValue={(selected) => {
                  return users
                    .filter((item: any) => selected.indexOf(item.value) > -1)
                    .map((item: any) => item.name)
                    .join(', ')
                }}
                displayEmpty
              >
                {users.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox checked={shareUsers && shareUsers.indexOf(item.value) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <Button
              variant="contained"
              style={{ fontSize: 16 }}
              disabled={shareUsers.length === 0 || shareIds.length === 0}
              onClick={handleShare}
            >
              Compartilhar
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default TableFixedMessages

import { FC } from 'react'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'

const Map: FC<{ location: string }> = ({ location }) => {
  const l = location.split(' # ')
  const lat = l[0]
  const long = l[1]

  return (
    <>
      <a href={`https://www.google.com.br/maps?q=${long},${lat}&z=10`} target="_blank">
        <div
          style={{
            width: 300,
            height: 75,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'url(/images/locationMap.jpg)',
            boxShadow: 'inset 0px 0px 15px #999',
          }}
        >
          <LocationOnIcon sx={{ fontSize: 32 }} />
        </div>
        <p style={{ textAlign: 'right', padding: '3px 0', fontSize: 14, lineHeight: 1.2 }}>
          Ver Mapa <DoubleArrowIcon sx={{ fontSize: 12 }} />
        </p>
      </a>
    </>
  )
}

export default Map

import styled from 'styled-components'

export const AudioElement = styled.audio`
    margin: 5px 0;
    width: calc(100%-60px);
    background-color: #fff;

    &::-webkit-media-controls-panel {
        background-color: #fff;
    }

    &::-webkit-media-controls-current-time-display,
    &::-webkit-media-controls-time-remaining-display {
        color: #651fff;
    }
`

export const SpeedRate = styled.div`
    width: 60px;
    background-color: #fff;
    height: 54px;
`
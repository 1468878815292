import { FC, useState } from 'react'

import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import FormGroup from '@mui/material/FormGroup'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import OutlinedInput from '@mui/material/OutlinedInput'
import TextField from '@mui/material/TextField'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

const Filter: FC<{
  post: any
  onChange: any
  onToggle: any
  onClear: any
  tags: any
  setores: any
  status: any
  users: any
  showFilter: boolean
  setShowFilter: any
}> = ({ post, tags, setores, status, users, onChange, onToggle, onClear, showFilter, setShowFilter }) => {
  const show = showFilter

  return (
    <div style={{ position: 'relative' }}>
      <Button 
        onClick={() => setShowFilter(true)}
        style={{
          fontSize: 10, 
          width: '100%',
          transition: 'opacity 150ms',
          opacity: !show ? 1 : 0,
          position: !show ? 'inherit' : 'absolute',
          zIndex: !show ? 1 : -1
        }}>
        Exibir Filtros <ExpandMoreIcon style={{ fontSize: 14 }} /> 
      </Button>

      <IconButton 
        onClick={() => setShowFilter(false)} 
        style={{ 
          position: 'absolute', 
          right: -5, 
          top: -2,
          transition: 'opacity 300ms',
          opacity: show ? 1 : 0,
          zIndex: show ? 1 : -1
        }}>
        <HighlightOffIcon style={{ fontSize: 14 }} />
      </IconButton>
      
      <FormGroup sx={{ transition: 'max-height 300ms linear', transform: 'scale(0.9)', maxHeight: show ? '300px' : '0px', overflow: 'hidden' }}>
        <Grid container spacing={2} sx={{ padding: '10px' }}>
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <TextField
                sx={{ '& input': { height: '20px' } }}
                id="text-search"
                name="search"
                label="Pesquisar"
                variant="outlined"
                size="small"
                value={post['search']}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel
                sx={{
                  marginTop: '-3px',
                }}
                id="select-tag"
              >
                Tag
              </InputLabel>
              <Select
                labelId="select-tag"
                multiple
                name="tag"
                label="Tag"
                value={post['tag'] || []}
                input={
                  <OutlinedInput
                    sx={{
                      height: '30px',
                    }}
                    label="Tag"
                  />
                }
                onChange={onChange}
                renderValue={(selected) => {
                  return tags
                    .filter((item: any) => selected.indexOf(item.value) > -1)
                    .map((item: any) => item.name)
                    .join(', ')
                }}
                displayEmpty
              >
                {tags.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox checked={post['tag'] && post['tag'].indexOf(item.value) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel
                sx={{
                  marginTop: '-3px',
                }}
                id="select-setor"
              >
                Setor
              </InputLabel>
              <Select
                labelId="select-setor"
                multiple
                name="setor"
                label="Setor"
                value={post['setor'] || []}
                input={
                  <OutlinedInput
                    sx={{
                      height: '30px',
                    }}
                    label="Setor"
                  />
                }
                onChange={onChange}
                renderValue={(selected) => {
                  return setores
                    .filter((item: any) => selected.indexOf(item.value) > -1)
                    .map((item: any) => item.name)
                    .join(', ')
                }}
                displayEmpty
              >
                {setores.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox checked={post['setor'] && post['setor'].indexOf(item.value) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel
                sx={{
                  marginTop: '-3px',
                }}
                id="select-status"
              >
                Status
              </InputLabel>
              <Select
                labelId="select-status"
                multiple
                name="status"
                label="Status"
                value={post['status'] || []}
                input={
                  <OutlinedInput
                    sx={{
                      height: '30px',
                    }}
                    label="Status"
                  />
                }
                onChange={onChange}
                renderValue={(selected) => {
                  return status
                    .filter((item: any) => selected.indexOf(item.value) > -1)
                    .map((item: any) => item.name)
                    .join(', ')
                }}
                displayEmpty
              >
                {status.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox checked={post['status'] && post['status'].indexOf(item.value) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel
                sx={{
                  marginTop: '-3px',
                }}
                id="select-user"
              >
                Usuário
              </InputLabel>
              <Select
                labelId="select-user"
                multiple
                name="user"
                label="Usuário"
                value={post['user'] || []}
                input={
                  <OutlinedInput
                    sx={{
                      height: '30px',
                    }}
                    label="Usuário"
                  />
                }
                onChange={onChange}
                renderValue={(selected) => {
                  return users
                    .filter((item: any) => selected.indexOf(item.value) > -1)
                    .map((item: any) => item.name)
                    .join(', ')
                }}
                displayEmpty
              >
                {users.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox checked={post['user'] && post['user'].indexOf(item.value) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6} sx={{ padding: '0 0 0 10px' }}>
            <FormControlLabel
              name="nao_lido"
              onChange={onToggle}
              control={<Switch checked={post['nao_lido']} size="small" />}
              label="Não lidos"
            />
          </Grid>
          <Grid item xs={6}>
            <Divider sx={{ margin: '0' }}>
              <Button onClick={onClear} variant="outlined" size="small">
                Limpar
              </Button>
            </Divider>
          </Grid>
        </Grid>
      </FormGroup>
    </div>
  )
}

export default Filter

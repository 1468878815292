import { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { FilterContext } from '../../../context/filter'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ForumIcon from '@mui/icons-material/Forum'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import SettingsIcon from '@mui/icons-material/Settings'

const MenuLeft: FC<{ open: boolean }> = ({ open }) => {
  const { newMessage } = useContext(FilterContext)
  const navigate = useNavigate()

  return (
    <List>
      {[
        {
          text: 'Conversas',
          icon: <ForumIcon sx={{ fontSize: 16 }} />,
          handleClick: () => navigate(`/chats`),
        },
        {
          text: 'Novo Chat',
          icon: <PersonAddAlt1Icon sx={{ fontSize: 16 }} />,
          handleClick: newMessage,
        },
        {
          text: 'Arquivos',
          icon: <AttachFileIcon sx={{ fontSize: 16 }} />,
          handleClick: () => navigate(`/files`),
        },
        {
          text: 'Mensagens',
          icon: <MarkEmailReadIcon sx={{ fontSize: 16 }} />,
          handleClick: () => navigate(`/default-messages`),
        },
        {
          text: 'Configurações',
          icon: <SettingsIcon sx={{ fontSize: 16 }} />,
          handleClick: () => (window.location.href = `/settings`),
        },
      ].map((item) => (
        <ListItem key={item.text} disablePadding>
          <ListItemButton
            sx={{
              justifyContent: open ? 'initial' : 'center',
              px: 2,
            }}
            onClick={item.handleClick}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.5 : 'auto',
                justifyContent: 'center',
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0, '& span': { fontSize: 14 } }} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default MenuLeft

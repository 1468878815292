import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { request } from '../../utils/fetcher'

import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import KeyIcon from '@mui/icons-material/Key'
import Box from '@mui/material/Box'
import PersonIcon from '@mui/icons-material/Person'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import Logo from '../../components/Logo'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState({
    user: '',
  })
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (
      localStorage.getItem('x-auth-chat') !== '' &&
      localStorage.getItem('x-auth-chat') !== null &&
      localStorage.getItem('x-auth-chat') !== undefined
    )
      navigate('/')
  }, [])

  const recoverPass = async (email: string) => {
    setLoading(true)

    await request('recover-password', { email })

    setMessage('Uma mensagem de recuperação de senha foi enviado para seu email caso ele seja um usuário válido.')

    setLoading(false)
  }

  const onChange = (e: any) => {
    setMessage('')
    setPost({ ...post, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e: any) => {
    setMessage('')

    e.preventDefault()

    const { user } = post

    if (user !== '') recoverPass(user)
  }

  return (
    <div
      style={{
        background: '#3800bc',
        height: '100dvh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box style={{ textAlign: 'center', paddingTop: '5%' }}>
        <Logo />

        {message !== '' && (
          <div
            style={{
              width: '280px',
              marginTop: '20px',
            }}
          >
            <Alert variant="filled" icon={<></>} severity="success">
              {message}
            </Alert>
          </div>
        )}

        <Paper style={{ width: '280px', padding: '50px 20px', marginTop: '20px' }} elevation={6}>
          <form>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <FormControl>
                <TextField
                  size="small"
                  sx={{ width: '100%', '& input': { fontSize: 14 } }}
                  value={post['user']}
                  name="user"
                  autoComplete="username"
                  onChange={onChange}
                  label={
                    <span style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                      <PersonIcon style={{ fontSize: 14 }} /> &nbsp; Usuário
                    </span>
                  }
                />
              </FormControl>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={() => navigate('/login')} style={{ fontSize: 10, textTransform: 'inherit' }}>
                  Voltar para Login
                </Button>
                <Button
                  type="submit"
                  disabled={loading}
                  size="small"
                  variant="contained"
                  style={{ fontSize: 12 }}
                  onClick={handleSubmit}
                >
                  {' '}
                  &nbsp; {loading ? (
                    <CircularProgress style={{ color: '#333', width: 14, height: 14 }} />
                  ) : (
                    `Enviar`
                  )}{' '}
                  &nbsp;{' '}
                </Button>
              </div>
            </Box>
          </form>
        </Paper>
      </Box>
    </div>
  )
}

export default ForgotPassword

import { FC, useState, useEffect, useRef } from 'react'
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Select,
  TableContainer,
  TextField,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import { request } from '../../utils/fetcher'
import GroupTable from './GroupTable'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

interface GroupType {
  id: number
  name: string
  children: {
    id: number
    name: string
    tagId: number
  }[]
  funil: number | string
  groupId: number
}

const Tags: FC = () => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [successMsg, setSuccessMsg] = useState('')
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [expanded, setExpanded] = useState<number[]>([])
  const [groups, setGroups] = useState<GroupType[]>([])
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    handleGetAllGroups()
  }, [])

  const handleGetAllGroups = async () => {
    setLoading(true)

    const data = await request(`company/tags`, {}, {}, { method: 'GET' })

    if (data.success) setGroups(data.groups)

    setLoading(false)
  }

  const handleChange = (id: number, e: any) => {
    setError('')
    setSuccessMsg('')
    setGroups(
      groups.map((group) => {
        if (group.id === id) return { ...group, [e.target.name]: e.target.value }
        else return group
      })
    )
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setError('')
    setSuccessMsg('')

    let error = ''
    const nodeInputs = formRef.current?.querySelectorAll('input')
    const inputs = Array.from(nodeInputs ? nodeInputs : []) as HTMLInputElement[]

    if (inputs)
      for (let input of inputs) {
        if (input.value.trim() === '') {
          error = 'Preencha os campos vazios.'
        }
      }

    if (error === '') {
      setLoading(true)
      const resp = await request(`company/tags`, { groups })
      if (resp.success) {
        setSuccessMsg('Tags salvas com sucesso!')
      } else {
        setError('Não foi possível salvar as tags, tente novamente.')
      }
      setLoading(false)
    } else setError(error)
  }

  const handleDeleteGroup = async (groupId: number) => {
    const confirm = window.confirm(
      'Tem certeza que deseja remover este grupo de tags? Todas as tags atreladas a este grupo serão removidas.'
    )

    if (confirm) {
      setLoading(true)

      const data = await request(`company/delete-tag-group`, { groupId })

      if (data.success) {
        setSuccessMsg('Grupo de tags removido com sucesso!')
        handleGetAllGroups()
        setTimeout(() => {
          setSuccessMsg('')
        }, 6000)
      } else {
        setError('Erro ao remover grupo de tags, tente novamente!')
      }

      setLoading(false)
    }
  }

  const handleDeleteTag = async (tagId: number) => {
    setError('')
    setSuccessMsg('')

    const confirm = window.confirm('Tem certeza que deseja remover esta tag?')

    if (confirm) {
      setLoading(true)

      const data = await request(`company/delete-tag`, { tagId })

      if (data.success) {
        setSuccessMsg('Tag removida com sucesso!')
        handleGetAllGroups()
        setTimeout(() => {
          setSuccessMsg('')
        }, 6000)
      } else {
        setError('Erro ao remover tag, tente novamente!')
      }

      setLoading(false)
    }
  }

  const handleDragEnd = (e: any) => {
    if (!e.destination) return
    let tempData = Array.from(groups)
    let [source_data] = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, source_data)
    setGroups(tempData)
  }

  const handleDragTagEnd = (id: number, children: any[]) => {
    setGroups(
      groups.map((group) => {
        if (group.id === id) return { ...group, children }
        else return group
      })
    )
  }

  const handleTagChange = (idGroup: number, idTag: number, name: string, value: string) => {
    setError('')
    setSuccessMsg('')
    setGroups(
      groups.map((group) => {
        if (group.id === idGroup)
          return {
            ...group,
            children: group.children.map((c) => {
              if (c.id === idTag) return { ...c, [name]: value }
              else return c
            }),
          }
        else return group
      })
    )
  }

  const newGroup = () => {
    setGroups([{ name: '', id: groups.length, children: [], funil: '', groupId: 0 }, ...groups])
  }

  const newTag = (id: number) => {
    setGroups(
      groups.map((group) => {
        if (group.id === id)
          return { ...group, children: [...group.children, { id: group.children.length, tagId: 0, name: '' }] }
        else return group
      })
    )
  }

  const displayError = error !== ''

  return (
    <form ref={formRef} id="form-tags" method="post" action="" onSubmit={handleSubmit}>
      <Modal
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              minWidth: 'auto',
              maxWidth: '400px',
              maxHeight: '90%',
              background: '#fff',
              borderRadius: '8px',
              position: 'relative',
              padding: '40px',
              overflowY: 'auto',
            }}
          >
            <div style={{ position: 'absolute', right: 0, top: 0 }}>
              <IconButton onClick={() => setConfirmDelete(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            Tem certeza que deseja remover esta tag?
            <div
              style={{
                margin: '15px 0 0 0',
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <Button onClick={() => {}} variant="contained" size="small" sx={{ width: 'auto' }}>
                Remover
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <div style={{ position: 'fixed', bottom: '20px', right: '40px', zIndex: 9 }}>
        <Button type="submit" variant="contained" sx={{ width: 'auto' }} size="large">
          Salvar
        </Button>
      </div>

      <div style={{ textAlign: 'right', marginBottom: 20 }}>
        <Button
          onClick={() => {
            newGroup()
          }}
          variant="contained"
          size="small"
          sx={{ width: 'auto' }}
        >
          Adicionar Grupo &nbsp; <AddCircleOutlineIcon fontSize={'small'} />
        </Button>
      </div>

      {displayError && (
        <>
          <Alert severity="error">{error}</Alert>
          <br />
          <br />
        </>
      )}
      {successMsg !== '' && (
        <>
          <Alert severity="success">{successMsg}</Alert>
          <br />
          <br />
        </>
      )}
      {loading && <LinearProgress />}

      <div style={{ paddingBottom: '100px' }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <div ref={provider.innerRef} {...provider.droppableProps}>
                {groups.map((row, index) => (
                  <Draggable key={row.id} draggableId={`group-${row.id}`} index={index}>
                    {(provider) => (
                      <Paper
                        {...provider.draggableProps}
                        ref={provider.innerRef}
                        sx={{ padding: '20px', margin: '0 0 20px 0' }}
                      >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div style={{ display: 'flex', flex: 1, gap: '15px', alignItems: 'center' }}>
                            <div {...provider.dragHandleProps}>
                              <DragIndicatorIcon />
                            </div>
                            <TextField
                              sx={{ width: '70%' }}
                              label="Grupo"
                              variant="outlined"
                              size="small"
                              name="name"
                              defaultValue={row.name}
                              error={displayError && row.name === ''}
                              onChange={(e: any) => handleChange(row.id, e)}
                            />
                            {row.funil === 1 && (
                              <span title="É Funil">
                                <FilterAltIcon color="primary" sx={{ fontSize: 16 }} />
                              </span>
                            )}
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                              onClick={() => {
                                if (!expanded.includes(row.id)) {
                                  setExpanded([...expanded, row.id])
                                } else {
                                  setExpanded(expanded.filter((e: number) => e !== row.id))
                                }
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {!expanded.includes(row.id) ? <ExpandLess /> : <ExpandMore />}
                            </span>
                          </div>
                        </div>

                        <div
                          style={{
                            paddingTop: '15px',
                            display: !expanded.includes(row.id) ? 'flex' : 'none',
                            flexDirection: 'column',
                            gap: '16px',
                          }}
                        >
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
                              <InputLabel id="label-funil">É Funil?</InputLabel>
                              <Select
                                labelId="label-funil"
                                value={row.funil}
                                label="É Funil?"
                                name="funil"
                                onChange={(e: any) => handleChange(row.id, e)}
                                error={displayError && row.funil === ''}
                              >
                                <MenuItem value={1}>Sim</MenuItem>
                                <MenuItem value={0}>Não</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          {row.children.length !== 0 && (
                            <TableContainer component={Paper}>
                              <GroupTable
                                idGroup={row.id}
                                data={row.children}
                                handleDragTagEnd={handleDragTagEnd}
                                handleChange={handleTagChange}
                                handleDelete={handleDeleteTag}
                                displayError={displayError}
                              />
                            </TableContainer>
                          )}

                          <div style={{ paddingTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                              onClick={() => handleDeleteGroup(row.groupId)}
                              size="small"
                              variant="outlined"
                              color="error"
                            >
                              Remover Grupo
                            </Button>

                            <Button
                              onClick={() => {
                                newTag(row.id)
                              }}
                              variant="outlined"
                              size="small"
                              sx={{ width: 'auto' }}
                            >
                              Adicionar Tag
                            </Button>
                          </div>
                        </div>
                      </Paper>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {displayError && <Alert severity="error">{error}</Alert>}
        {successMsg !== '' && (
          <>
            <Alert severity="success">{successMsg}</Alert>
            <br />
            <br />
          </>
        )}
        {loading && <LinearProgress />}
      </div>
    </form>
  )
}

export default Tags

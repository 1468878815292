import { FC } from 'react'

import Typography from '@mui/material/Typography'

const Logo: FC= () => {
    return (
        <Typography variant="h6" noWrap component="div" style={{ color: '#fff' }}>
            multiChat
        </Typography>
    )
}

export default Logo

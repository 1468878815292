export const filterDefaultValues = {
  search: '',
  tag: [],
  setor: [],
  user: [],
  status: [],
  nao_lido: false,
} as any

export const status = [
  { value: 2, name: 'Auto Atendimento' },
  { value: 3, name: 'Aguarda Atendimento' },
  { value: 1, name: 'Em Atendimento' },
  { value: 4, name: 'Finalizados' },
]

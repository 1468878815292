import { FC, createContext, useEffect, useState } from 'react'

import { FilterContextTypes } from './types'

import { request } from '../utils/fetcher'

import { status } from '../constants/filter'
import NewMessageModal from '../components/NewMessageModal'

export const FilterContext = createContext({} as FilterContextTypes)

export const FilterContextProvider: FC<{ children: any }> = ({ children }) => {
  const [openNewMessageModal, setOpenNewMessageModal] = useState(false)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [filtersOptions, setFiltersOptions] = useState({ setores: [], tags: [], users: [], status, search: '' })

  useEffect(() => {
    if (isUserLoggedIn) setAllFilterOptions()
  }, [isUserLoggedIn])

  const setAllFilterOptions = async () => {
    const data = await request(`filter`, {}, {}, { method: 'GET' })

    if (data.success) {
      data.departments &&
        data.tags &&
        data.users &&
        setFiltersOptions({
          ...filtersOptions,
          setores: data.departments,
          tags: data.tags,
          users: data.users,
        })
    }
  }

  const handleLogin = () => setIsUserLoggedIn(true)

  const handleLogout = () => setIsUserLoggedIn(false)

  const newMessage = () => {
    setOpenNewMessageModal(true)
  }

  return (
    <FilterContext.Provider
      value={{
        filtersOptions,
        newMessage,
        handleLogin,
        handleLogout,
      }}
    >
      {filtersOptions.users.length > 0 && (
        <NewMessageModal
          handleClose={() => setOpenNewMessageModal(false)}
          opened={openNewMessageModal}
          setores={filtersOptions.setores}
        />
      )}

      {children}
    </FilterContext.Provider>
  )
}

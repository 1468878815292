import { FC } from 'react'

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { getExtension } from '../../../../../../../utils/files'

const Document: FC<{ src: string; size?: { width: number; height: number } }> = ({ src, size }) => {
  const width = size?.width ? size.width : 300
  const height = size?.height ? size.height : 150

  const ext = getExtension(src)

  return (
    <div style={{ width: `${width}px`, height: `${height}px` }}>
      <a
        href={src}
        target="_blank"
        style={{
          margin: '5px 0',
          boxShadow: '1px 1px 5px #999',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <InsertDriveFileIcon />.{ext}
      </a>
    </div>
  )
}

export default Document

import { FC } from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { IconButton, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

const GroupTable: FC<{
  idGroup: number
  data: any
  handleDragTagEnd: any
  handleChange: any
  handleDelete: any
  displayError: boolean
}> = ({ idGroup, data, handleDragTagEnd, handleChange, handleDelete, displayError }) => {
  const handleDragEnd = (e: any) => {
    if (!e.destination) return
    let tempData = Array.from(data)
    let [source_data] = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, source_data)
    handleDragTagEnd(idGroup, tempData)
  }

  const handleValueChange = (id: number, e: any) => {
    handleChange(idGroup, id, e.target.name, e.target.value)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Table size="small" aria-label="simple table">
        <Droppable droppableId="droppable-1">
          {(provider) => (
            <TableBody ref={provider.innerRef} {...provider.droppableProps}>
              {data.map((row: any, index: number) => (
                <Draggable key={row.id} draggableId={`tag-${row.id}`} index={index}>
                  {(provider) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      {...provider.draggableProps}
                      ref={provider.innerRef}
                    >
                      <TableCell width={'50px'} component="th" scope="row" {...provider.dragHandleProps}>
                        <DragIndicatorIcon />
                      </TableCell>
                      <TableCell>
                        <TextField
                          sx={{ width: '70%' }}
                          label="Tag"
                          variant="outlined"
                          size="small"
                          name="name"
                          defaultValue={row.name}
                          error={displayError && row.name === ''}
                          onChange={(e: any) => handleValueChange(row.id, e)}
                        />
                      </TableCell>
                      <TableCell width={'50px'} style={{ textAlign: 'right' }}>
                        <IconButton onClick={() => handleDelete(row.tagId)} color="error">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </Draggable>
              ))}
              {provider.placeholder}
            </TableBody>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  )
}

export default GroupTable

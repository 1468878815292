import { FC, InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import EditOffIcon from '@mui/icons-material/EditOff'
import Input from '@mui/material/Input'
import { request } from '../../../../utils/fetcher'

const Exhibition:FC<{ name: string, id: string }> = ({ id, name }) => {
    const [edit, setEdit] = useState(false)
    const [saving, setSaving] = useState(false)
    const [displayName, setDisplayName] = useState(name)
    const fieldRef = useRef<any>()

    useEffect(() => {
        if (edit) fieldRef?.current?.addEventListener('keypress', keyPress)
        else fieldRef?.current?.removeEventListener('keypress', keyPress)
    }, [edit])

    useEffect(() => {
        setDisplayName(name)
    }, [name])

    const keyPress = (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault()
            saveDisplayName(event.target.value)
        }
    }

    const saveDisplayName = async (name: string) => {
        setSaving(true)
        await request(`client/${id}`, { name })
        setSaving(false)
        setEdit(false)
    }

    const handleChange = (e: any) => {
        setDisplayName(e.target.value)
    }

    const toggleEdit = () => {
        if (!saving) {
            setEdit(prev => { 
                if (prev) {
                    saveDisplayName(displayName)
                    return edit
                }
                else return !edit 
            })
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {!edit && (
                <Typography 
                    variant="h6" 
                    component="h2" 
                    sx={{ 
                        fontSize: '18px', 
                        marginRight: '5px',
                        maxWidth: '245px',
                        minWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>
                    {displayName}
                </Typography>
            )}
            {edit && <Input inputRef={fieldRef} value={displayName} onChange={handleChange} disabled={saving} />}

            <IconButton aria-label="delete" size="small" sx={{ width: 18, height: 18 }} onClick={toggleEdit}>
                {!edit && <EditIcon sx={{ fontSize: '16px' }} />}
                {edit && <EditOffIcon sx={{ fontSize: '16px' }} />}
            </IconButton>
        </div>
    )
}

export default Exhibition
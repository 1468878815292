import { FC, useEffect, useState } from 'react'

import { Button, IconButton, List, ListItemButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { request } from '../../../../../utils/fetcher'

const Messages: FC<{
  handleClose: () => void
  handleChange: any
  handleSubmit: any
  text: any
}> = ({ handleClose, handleChange, handleSubmit, text }) => {
  const [messages, setMessages] = useState<any[]>([])

  useEffect(() => {
    loadAllDefaultMessages()
  }, [])

  const loadAllDefaultMessages = async () => {
    const data = await request(`company/default-messages`, {}, {}, { method: 'GET' })

    if (data.success && data.messages.length > 0) setMessages(data.messages)
  }

  return (
    <>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <IconButton
          onClick={() => {
            handleClose()
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <div
        style={{
          width: '70vw',
        }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '550px' }}>
            <div style={{ display: 'flex', flex: 1 }}>
              <textarea
                placeholder="Mensagem:"
                value={text}
                onChange={handleChange}
                style={{
                  backgroundColor: '#fff',
                  color: '#333',
                  border: '1px solid #e8e8e8',
                  width: '100%',
                  flex: 1,
                  padding: '15px 30px',
                  resize: 'none',
                  fontSize: '16px',
                }}
              ></textarea>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <Button
                variant="outlined"
                onClick={() => {
                  const e = {
                    target: {
                      value: '',
                    },
                  }
                  handleChange(e)
                }}
              >
                Limpar
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit()
                  handleClose()
                }}
              >
                Enviar
              </Button>
            </div>
          </div>
          <div style={{ width: '400px' }}>
            <List
              sx={{
                overflowY: 'auto',
                height: '494px',
                padding: 0,
                borderTop: '1px solid #ccc',
                borderBottom: '1px solid #ccc',
              }}
            >
              {messages.map((msg: any, i: number) => {
                const { FixedMSG_id: id, FixedMSG_Label: title, FixedMSG_FullTXT: message } = msg

                return (
                  <ListItemButton
                    key={id}
                    onClick={() => handleChange({ target: { value: message } })}
                    sx={{
                      borderBottom: '1px solid #ccc',
                      flexDirection: 'column',
                      alignItems: 'start',
                      '&:lastChild': { borderBottom: 0 },
                    }}
                    title={message}
                  >
                    <h6 style={{ fontSize: 10, fontWeight: 'normal', color: '#999', marginBottom: '3px' }}>{title}</h6>
                    <p style={{ fontSize: 12, lineHeight: '18px' }}>
                      {message.length > 150 ? `${message.substring(0, 150)}...` : message}
                    </p>
                  </ListItemButton>
                )
              })}
            </List>
          </div>
        </div>
      </div>
    </>
  )
}

export default Messages

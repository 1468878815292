import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  body, html {
    font-weight: normal;
    font-size: ${({ theme }) => theme.fonts.sizes.root};
    font-family: ${({ theme }) => theme.fonts.primary};
    line-height: 1.2;
    overflow-y: hidden;
  }

  p, link, input, textarea, a, button {
    font-family: ${({ theme }) => theme.fonts.primary};
    text-decoration: none;
  }

  h1, h2, h3, h4 {
    font-family: ${({ theme }) => theme.fonts.primary};
  }

  .format-message {}
  .format-message a { color: ${({ theme }) => theme.colors.primary}; }
  .format-message .meta { padding: 10px; background: #fff; box-shadow: 3px 5px 5px #ccc; margin: 10px 0; }
  .format-message .meta h4 {font-size: 0.75rem; margin: 0;}
  .format-message .meta p {font-size: 0.65rem; margin: 0;}
  .format-message .meta img {width: auto !important; max-width: 100% !important; max-height: 80px;}

  @media (max-width: 899px) {
    .chat-list {
      overflow: hidden;
    }

    .mobile-hide {
      display: none !important;
    }

    .mobile-full {
      width: 100% !important;
    }

    .header-chat {
      display: block !important;
    }

    .header-chat h2 {
      font-size: 14px !important;
    }
  }
`

import {
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tab,
  Tabs,
  FormControl,
  TextField,
} from '@mui/material'
import { FC, useContext, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import DefaultFileComponent from '../DefaultFileComponent'
import SendIcon from '@mui/icons-material/Send'
import { FilterContext } from '../../context/filter'

const TableFixedFiles: FC<{
  files: any[]
  handleDelete?: any
  handleSend?: any
  filterTag?: number
  showTag?: boolean
  size?: string
}> = ({ files, handleDelete, handleSend, filterTag, showTag, size }) => {
  const { filtersOptions } = useContext(FilterContext)
  const [filterWho, setFilterWho] = useState<number | string>(0)
  const [filterLabel, setFilterLabel] = useState('')

  const getTagName = (tag: any) => {
    const filtered = filtersOptions.tags.filter((t: any) => Number(t.value) === Number(tag))

    if (filtered.length === 0) return 'sem tag'
    else return filtered[0].name
  }

  let filteredFiles = files
    .filter((file: any) => {
      if (filterWho) {
        if (filterWho === 0) return true
        else if (
          typeof filterWho === 'string' &&
          filterWho.includes('1-') &&
          file.FixedFiles_DepartmentID === Number(filterWho.split('-')[1])
        )
          return true
        else if (filterWho === 2 && file.FixedFiles_EmployeeID > 0) return true
        else return false
      }

      return true
    })
    .filter((file: any) => {
      if (filterTag && filterTag > 0) {
        return file.FixedFiles_TagID === filterTag
      }

      return true
    })
    .filter((file: any) => {
      if (filterLabel.trim() !== '') {
        return file.FixedFiles_FileLabel.includes(filterLabel.trim())
      }

      return true
    })

  return (
    <>
      <div style={{ width: '50dvw', margin: '0 0 15px 0' }}>
        <FormControl fullWidth size="small" style={{ position: 'relative' }}>
          <TextField
            id="text-label"
            name="label"
            label="Label *"
            variant="outlined"
            size="small"
            value={filterLabel}
            onChange={(e: any) => setFilterLabel(e.target.value)}
          />
        </FormControl>
      </div>

      <Tabs
        value={filterWho}
        onChange={(event: React.SyntheticEvent, newValue: number) => {
          setFilterWho(newValue)
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Todos" />
        {filtersOptions.setores.map((item: any) => (
          <Tab key={item.value} label={`Setor ${item.name}`} value={`1-${item.value}`} />
        ))}
        <Tab label="Apenas Eu" />
      </Tabs>

      {filteredFiles.length === 0 && <div style={{ padding: '20px', fontSize: 14 }}>Nenhum arquivo encontrado.</div>}

      {filteredFiles.length > 0 && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Arquivo</TableCell>
                <TableCell>Label</TableCell>
                {showTag && <TableCell>Tag</TableCell>}
                <TableCell>Criado em</TableCell>
                <TableCell> &nbsp; </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFiles.map((file: any, i: number) => {
                const {
                  fileCount,
                  FixedFiles_id: id,
                  FixedFiles_FileLabel: name,
                  FixedFiles_FileLink: path,
                  FixedFiles_TagID: tag,
                  FixedFiles_createdDate: created_at,
                } = file

                return (
                  <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      <DefaultFileComponent file={{ path, name }} size={size ? size : 'small'} />
                    </TableCell>
                    <TableCell>{name}</TableCell>
                    {showTag && <TableCell>{getTagName(tag)}</TableCell>}
                    <TableCell>{created_at}</TableCell>
                    <TableCell>
                      {handleDelete && (
                        <Fab onClick={() => handleDelete(name, id, fileCount)} size="small" color="error">
                          <DeleteIcon />
                        </Fab>
                      )}
                      {handleSend && (
                        <Fab onClick={() => handleSend(path)} size="small" color="primary">
                          <SendIcon sx={{ fontSize: 12 }} />
                        </Fab>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default TableFixedFiles

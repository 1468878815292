import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { request } from '../../utils/fetcher'

import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import KeyIcon from '@mui/icons-material/Key'
import Box from '@mui/material/Box'
import PersonIcon from '@mui/icons-material/Person'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import Logo from '../../components/Logo'
import { FilterContext } from '../../context/filter'

const Login = () => {
  const navigate = useNavigate()
  const { handleLogin } = useContext(FilterContext)
  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState({
    user: '',
    pass: '',
  })
  const [companies, setCompanies] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (
      localStorage.getItem('x-auth-chat') !== '' &&
      localStorage.getItem('x-auth-chat') !== null &&
      localStorage.getItem('x-auth-chat') !== undefined
    )
      navigate('/')
  }, [])

  const handleError = (error: string) => {
    if (error === 'USER_LOGIN_ERROR') {
      setErrorMessage('Usuário ou senha inválidos.')
    } else if (error === 'DATA_NOT_FOUND_ERROR') {
      setErrorMessage('Usuário ou senha inválidos.')
    } else if (error === 'USER_CANT_LOGIN_DATETIME') {
      setErrorMessage('Usuário não habilitado para entrar no sistema neste dia/hora.')
    }
  }

  const doLogin = async (email: string, password: string) => {
    setLoading(true)

    const data = await request('auth', { email, password })

    if (data?.success) {
      if (data.user.companies.length > 0) {
        setCompanies(data.user.companies)
      }
    } else {
      handleError(data.error)
    }

    setLoading(false)
  }

  const handleSelectCompany = async (companyId: string, email: string, password: string) => {
    setLoading(true)

    const data = await request('auth-company', { companyId, email, password })

    if (data.success) {
      handleLogin()
      localStorage.setItem('x-auth-chat', data.jwt)
      navigate(`/`)
    } else {
      setCompanies([])
      handleError(data.error)
    }

    setLoading(false)
  }

  const onChange = (e: any) => {
    setErrorMessage('')
    setPost({ ...post, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e: any) => {
    setErrorMessage('')

    e.preventDefault()

    const { user, pass } = post

    if (user !== '' && pass !== '') doLogin(user, pass)
  }

  return (
    <div
      style={{
        background: '#3800bc',
        height: '100dvh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box style={{ textAlign: 'center', paddingTop: '5%' }}>
        <Logo />

        {errorMessage !== '' && (
          <div
            style={{
              width: '280px',
              marginTop: '20px',
            }}
          >
            <Alert variant="filled" icon={<></>} severity="error">
              {errorMessage}
            </Alert>
          </div>
        )}

        <Paper style={{ width: '280px', padding: '50px 20px', marginTop: '20px' }} elevation={6}>
          <form>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <FormControl>
                <TextField
                  size="small"
                  sx={{ width: '100%', '& input': { fontSize: 14 } }}
                  value={post['user']}
                  name="user"
                  autoComplete="username"
                  onChange={onChange}
                  disabled={companies.length > 0}
                  label={
                    <span style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                      <PersonIcon style={{ fontSize: 14 }} /> &nbsp; Usuário
                    </span>
                  }
                />
              </FormControl>
              <FormControl>
                <TextField
                  type="password"
                  size="small"
                  name="pass"
                  sx={{ width: '100%', '& input': { fontSize: 14 } }}
                  value={post['pass']}
                  autoComplete="current-password"
                  onChange={onChange}
                  label={
                    <span style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                      <KeyIcon style={{ fontSize: 14 }} /> &nbsp; Password
                    </span>
                  }
                  disabled={companies.length > 0}
                />
              </FormControl>

              {companies.length > 0 && (
                <div
                  style={{ width: '100%', color: '#3800bc', fontSize: 12, textAlign: 'center', fontWeight: 'strong' }}
                >
                  Selecione a Empresa:
                </div>
              )}
              {companies.map((c: any, i) => (
                <Button
                  key={i}
                  type="button"
                  disabled={loading}
                  size="small"
                  variant="outlined"
                  style={{ fontSize: 12 }}
                  onClick={() => handleSelectCompany(c.id, post['user'], post['pass'])}
                >
                  {c.name}
                </Button>
              ))}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={() => navigate('/forgot-password')} style={{ fontSize: 10, textTransform: 'inherit' }}>
                  Esqueci a senha
                </Button>
                <Button
                  type="submit"
                  disabled={loading || companies.length > 0}
                  size="small"
                  variant="contained"
                  style={{ fontSize: 12 }}
                  onClick={handleSubmit}
                >
                  {' '}
                  &nbsp; {loading ? (
                    <CircularProgress style={{ color: '#333', width: 14, height: 14 }} />
                  ) : (
                    `Entrar`
                  )}{' '}
                  &nbsp;{' '}
                </Button>
              </div>
            </Box>
          </form>
        </Paper>
      </Box>
    </div>
  )
}

export default Login

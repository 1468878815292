import { FC, useState } from 'react'

import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { Button } from '@mui/material'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import dayjs from 'dayjs'

const ScheduleMessageForm: FC<{ 
    scheduleDate: any,
    setDateSchedule: any,
    handleClose: () => void, 
}> = ({ scheduleDate, setDateSchedule, handleClose }) => {
    const [error, setError] = useState<string | null>(null)

    return (
        <> 
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                    <DateTimePicker
                        minDateTime={dayjs()}
                        format='DD/MM/YYYY HH:mm'
                        label="Agendar para"
                        value={scheduleDate}
                        componentsProps={{
                            actionBar: {
                              actions: ['clear', 'accept'],
                            },
                          }}
                          sx={{ margin: '10px 0 0 0' }}
                        onChange={(newValue) => {
                            if (newValue <= dayjs())
                                newValue = dayjs().add(30, 'm')

                            setDateSchedule(newValue)
                        }}
                        onError={e => setError(e)}
                        onAccept={() => setError(null)}
                    />
                </DemoContainer>
            </LocalizationProvider>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <Button 
                    variant="outlined" 
                    onClick={() => {
                        setDateSchedule(null)
                        handleClose()
                    }}>
                    Cancelar
                </Button>

                <Button 
                    variant="contained" 
                    onClick={() => {
                        if (isNaN(scheduleDate) || error !== null) setDateSchedule(null)
                        else if (scheduleDate) handleClose()
                    }}>
                    Salvar
                </Button>
            </div>
        </>
    )
}

export default ScheduleMessageForm
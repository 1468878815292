const URL = 'https://api.multichat.app.br'

type RequestOptions = {
  method?: string
  querystring?: {}
  upload?: boolean
}

export const request = async (url: string, body?: any, headers?: any, options?: RequestOptions) => {
  const error = { success: false }

  let urlString = `${URL}/${url}`
  if (options?.querystring) urlString = `${urlString}?${new URLSearchParams(options.querystring).toString()}`

  try {
    const requestHeaders = {
      Authorization: `Bearer ${localStorage.getItem('x-auth-chat')}`,
      ...headers,
    }

    if (!options?.upload) {
      requestHeaders['Content-Type'] = 'application/json'
    }

    let requestOptions = {
      method: options?.method ?? 'POST',
      headers: requestHeaders,
    } as any

    if (requestOptions.method === 'POST') {
      if (options?.upload) {
        const formData = new FormData()

        Object.keys(body).forEach((key) => {
          if (key === 'file') {
            for (const file of body['file']) {
              formData.append('files[]', file, file.name)
            }
          } else {
            formData.append(key, body[key])
          }
        })

        requestOptions['body'] = formData
      } else {
        requestOptions['body'] = JSON.stringify(body)
      }
    }

    const res = await fetch(urlString, requestOptions)

    const json = await res.json()

    if (json.status === 'error' && json.code !== 200) {
      console.log(json)
      localStorage.setItem('x-auth-chat', '')

      if (typeof window !== 'undefined') window.dispatchEvent(new Event('storage'))
    }

    if (json.errors || json.status === 'error') {
      console.log(json.errors, json.message)

      return error
    }

    return json
  } catch (e: any) {
    console.log('Exception', e.message)
  }

  return error
}

import { Grid, LinearProgress, Box } from '@mui/material'

import Layout from '../../components/Layout'
import Filter from '../../components/Chat/Filter'
import ChatList from '../../components/Chat/ChatList'
import UserChat from '../../components/Chat/UserChat'

import { useParams } from 'react-router-dom'

import { FC, useContext, useEffect, useState } from 'react'
import { DataContext, DataContextProvider } from '../../context'
import { request } from '../../utils/fetcher'
import { FilterContext } from '../../context/filter'

const ChatPage: FC<{ list?: boolean }> = ({ list }) => {
  const [showFilter, setShowFilter] = useState(false)
  const { id } = useParams()
  const [chatData, setChatData] = useState(null)

  const {
    clearChatsInterval,
    post,
    loading,
    chatsList,
    handleChange,
    handleClear,
    handleToggle,
    handleOldChats,
    incrementLimit,
    resetLimit,
    newChatsLoading,
    oldChatsLoading,
  } = useContext(DataContext)
  const { filtersOptions } = useContext(FilterContext)

  useEffect(() => {
    setChatData(null)
    handleChatData(id || '')

    return () => {
      setChatData(null)
    }
  }, [id])

  const handleChatData = async (chatId: string) => {
    if (chatId !== '' && parseInt(chatId) === parseInt(id || '')) {
      const resp = await request(`chat/${chatId}`, {}, {}, { method: 'GET' })
      if (resp.success) {
        setChatData(resp.chat)
      }
    }
  }

  const seeMoreChats = () => {
    clearChatsInterval()
    handleOldChats()
    incrementLimit()
  }

  const handleScrollTop = () => {
    resetLimit()
  }

  return (
    <Layout>
      <Grid
        container
        className="chat-list"
        sx={{
          height: 'calc(100% - 17px)',
          overflowY: 'hidden',
        }}
      >
        <Grid item md={3.5} xs={12} sx={{ borderRight: '1px solid #e0e0e0' }} className={!list ? 'mobile-hide' : ''}>
          <Filter
            post={post}
            tags={filtersOptions?.tags}
            setores={filtersOptions?.setores}
            users={filtersOptions?.users}
            status={filtersOptions?.status}
            onChange={handleChange}
            onToggle={handleToggle}
            onClear={handleClear}
            setShowFilter={setShowFilter}
            showFilter={showFilter}
          />

          <Box sx={{ width: '100%', height: '1px', overflowY: 'hidden' }}>{newChatsLoading && <LinearProgress />}</Box>

          <ChatList
            seeMoreChats={seeMoreChats}
            handleScrollTop={handleScrollTop}
            loading={loading}
            list={chatsList}
            selected={id}
            showFilter={showFilter}
          />

          <Box sx={{ width: '100%', height: '1px', overflowY: 'hidden' }}>{oldChatsLoading && <LinearProgress />}</Box>
        </Grid>
        <Grid item md={8.5} xs={12} style={{ height: '100%' }}>
          {chatData && (
            <UserChat
              tags={filtersOptions.tags}
              data={chatData}
              setores={filtersOptions.setores}
              users={filtersOptions.users}
              handleChatData={handleChatData}
            />
          )}
        </Grid>
      </Grid>
    </Layout>
  )
}

const Chat: FC<{ list?: boolean }> = ({ list }) => {
  return (
    <DataContextProvider>
      <ChatPage list={list} />
    </DataContextProvider>
  )
}

export default Chat

const theme = {
  title: 'default-theme',

  colors: {
    black: '#000000',
    white: '#FFFFFF',
    primary: '#651fff',
    text: {
      p: '#111111',
      title: '#111111',
    },
  },
  fonts: {
    primary: 'Roboto, sans-serif',
    sizes: {
      root: '16px',
    },
  },
  width: {
    max: '560px',
  },
}

export default theme

import { FC, useState } from 'react'

import { Button, IconButton, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'
import ImageIcon from '@mui/icons-material/Image'
import MicIcon from '@mui/icons-material/Mic'
import VideocamIcon from '@mui/icons-material/Videocam'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

import { request } from '../../../../../utils/fetcher'
import Loading from '../../../../Loading'
import Message from '../../Messages/Message'
import { getExtension } from '../../../../../utils/files'

const ScheduledMessagesList: FC<{ chatId: any; clientId: any; handleClose: () => void }> = ({
  chatId,
  clientId,
  handleClose,
}) => {
  const [list, setList] = useState([])
  const [openMessage, setOpenMessage] = useState<string | number>(0)

  const getAllMessages = async () => {
    const resp = await request(`schedule-messages/${clientId}`, {}, {}, { method: 'GET' })
    resp.success && setList(resp.messages)

    if (resp.messages.length === 0) handleClose()
  }

  const handleSend = async (message_id: string | number) => {
    setList([])
    await request(`send-schedule-message/${message_id}`)
    getAllMessages()
  }

  const handleDelete = async (message_id: string | number) => {
    setList([])
    await request(`delete-schedule-message/${message_id}`)
    getAllMessages()
  }

  const getMessage = (id: string | number, type: string | number, message: string) => {
    let mensagem = null
    const buttonSeeMore = (
      <Button sx={{ fontSize: 10 }} onClick={() => setOpenMessage(id)}>
        ver +
      </Button>
    )
    const buttonSeeLess = (
      <Button sx={{ fontSize: 10 }} onClick={() => setOpenMessage(0)}>
        ver -
      </Button>
    )

    if (type === 0)
      if (message.length > 42) {
        if (openMessage !== id)
          mensagem = (
            <>
              {message.substring(0, 42)}... {buttonSeeMore}
            </>
          )
        else
          mensagem = (
            <>
              {message}
              <br />
              {buttonSeeLess}
            </>
          )
      } else mensagem = message
    else {
      if (openMessage !== id) {
        switch (getExtension(message)) {
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'gif':
            mensagem = (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImageIcon sx={{ fontSize: 20 }} /> &nbsp; Imagem &nbsp; {buttonSeeMore}
              </div>
            )
            break
          case '3gp':
          case 'mp4':
            mensagem = (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <VideocamIcon sx={{ fontSize: 20 }} /> &nbsp; Vídeo &nbsp; {buttonSeeMore}
              </div>
            )
            break
          case 'acc':
          case 'amr':
          case 'mpeg':
          case 'ogg':
          case 'wav':
          case 'mp3':
          case 'webm':
            mensagem = (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MicIcon sx={{ fontSize: 20 }} /> &nbsp; Audio &nbsp; {buttonSeeMore}
              </div>
            )
            break
          default:
            mensagem = (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InsertDriveFileIcon sx={{ fontSize: 20 }} /> &nbsp; Documento &nbsp; {buttonSeeMore}
              </div>
            )
            break
        }
      } else {
        mensagem = (
          <>
            <Message type={Number(type)} size={{ width: 350, height: 200 }}>
              {message}
            </Message>
            <br />
            {buttonSeeLess}
          </>
        )
      }
    }

    return mensagem
  }

  getAllMessages()

  if (list.length === 0) return <Loading />

  return (
    <div style={{ overflow: 'auto', height: '70dvh' }}>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <table
        style={{
          width: '765px',
        }}
        cellSpacing={15}
      >
        <thead>
          <td style={{ fontSize: 11 }}> &nbsp; </td>
          <td style={{ fontSize: 11, width: '10%' }}>Data da Criação</td>
          <td style={{ fontSize: 11, width: '70%' }}>Mensagem</td>
          <td style={{ fontSize: 11, width: '10%' }}>Criada por</td>
          <td style={{ fontSize: 11, width: '10%' }}>Data Agendada</td>
          <td style={{ fontSize: 11 }}> &nbsp; </td>
        </thead>
        <tbody>
          {list.map((msg: any, i: number) => {
            return (
              <tr key={i}>
                <td>
                  <IconButton onClick={() => handleSend(msg.message_id)} title="Enviar agora">
                    <SendIcon />
                  </IconButton>
                </td>
                <td style={{ fontSize: 9 }}> {msg.datetime} </td>
                <td style={{ fontSize: 14 }}>
                  {getMessage(msg.message_id, msg.message_type, msg.message_type_detail)}
                </td>
                <td style={{ fontSize: 9 }}> {msg.employee_name} </td>
                <td style={{ fontSize: 9 }}> {msg.datetime_schedule} </td>
                <td>
                  <IconButton
                    onClick={() => handleDelete(msg.message_id)}
                    sx={{ color: 'red' }}
                    title="Remover agendamento"
                  >
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default ScheduledMessagesList

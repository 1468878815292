const PageNotFound = () => {
  return (
    <>
      <h1>404</h1>
      <p>Página não encontrada.</p>
    </>
  )
}

export default PageNotFound

import { FC, useState } from 'react'

import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { Button, Checkbox, TextField } from '@mui/material'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import dayjs, { Dayjs } from 'dayjs'
import { request } from '../../../../../utils/fetcher'

const ScheduleMessageForm: FC<{
  chatId: any
  clientPhone: any
  deviceId: any
  status: any
  handleClose: () => void
  refreshChatList: any
}> = ({ chatId, clientPhone, deviceId, status, handleClose, refreshChatList }) => {
  const [error, setError] = useState<string | null>(null)
  const [label, setLabel] = useState(
    status.status_type === 2 && status.status_label && status.status_label !== '' ? status.status_label : 'Adiado'
  )
  const [delayDate, setDelayDate] = useState<Dayjs | null>(
    status.status_type === 2 && status.status_date_validity && Number(status.status_date_validity) > 0
      ? dayjs.unix(status.status_date_validity)
      : dayjs().add(30, 'm')
  )
  const [abort, setAbort] = useState(
    status.status_abortIFmessageReceived && Number(status.status_abortIFmessageReceived) === 0 ? 0 : 1
  )

  const handleSubmit = async () => {
    status.setActualStatus(2)

    const body = {
      statusLabel: label,
      statusDateValidity: delayDate?.unix() || dayjs().add(30, 'm'),
      abort,
      telefone: clientPhone,
      deviceId,
      status: 2,
    }

    await request(`chat/${chatId}/status`, body)
    refreshChatList(true)
    handleClose()
  }

  return (
    <>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
          <DateTimePicker
            minDateTime={dayjs()}
            format="DD/MM/YYYY HH:mm"
            label="Adiar até"
            value={delayDate}
            componentsProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            onChange={(newValue) => {
              if (newValue && newValue <= dayjs()) newValue = dayjs().add(30, 'm')

              setDelayDate(newValue)
            }}
            sx={{ margin: '10px 0 0 0' }}
            onError={(e) => setError(e)}
            onAccept={() => setError(null)}
          />
        </DemoContainer>
      </LocalizationProvider>

      <div style={{ margin: '20px 0 10px 0' }}>
        <TextField
          label="Personalize"
          placeholder="Adiado"
          value={label}
          variant="outlined"
          inputProps={{ maxLength: 10 }}
          sx={{ width: '100%' }}
          onChange={(e) => setLabel(e.target.value)}
        />
      </div>

      <div>
        <label>
          <Checkbox checked={abort === 1} onChange={(e) => setAbort(e.target.checked ? 1 : 0)} size="small" />
          <span style={{ fontSize: 12, paddingRight: 15 }}>
            Retornar para Atendimento caso o cliente envie mensagem
          </span>
        </label>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button variant="contained" onClick={handleSubmit}>
          Salvar
        </Button>
      </div>
    </>
  )
}

export default ScheduleMessageForm

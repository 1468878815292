import { FC } from 'react'

const Video: FC<{ src: string, extension: string, size?: { width: number, height: number } }> = ({ src, extension, size }) => {
    const width = size?.width ? size.width : 300
    const height = size?.height ? size.height : 150
    
    return ( 
        <div style={{ width: `${width}px`, height: `${height}px` }}>
            <video width={width} height={height} style={{ margin: '5px 0', boxShadow: '0 0 5px #ccc', width: '100%', height: '100%', objectFit: 'cover' }} controls>
                <source src={src} type={`video/${extension}`} />
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

export default Video
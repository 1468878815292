import { FC, useState } from 'react'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import dayjs, { Dayjs } from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import { request } from '../../../../../utils/fetcher'

const AssignUserForm: FC<{
  clientId: any
  handleClose: () => void
  users: any
  employee_fixed: any
  employee_fixed_max_date: any
  setEmployeeFixed: any
  setEmployeeFixedMaxDate: any
}> = ({
  clientId,
  handleClose,
  users,
  employee_fixed,
  employee_fixed_max_date,
  setEmployeeFixed,
  setEmployeeFixedMaxDate,
}) => {
  const [user, setUser] = useState(employee_fixed)
  const [date, setDate] = useState<Dayjs | null>(
    employee_fixed_max_date !== 0 ? dayjs.unix(employee_fixed_max_date) : null
  )

  const handleSave = async () => {
    await request(`messages/${clientId}/assign-user`, { user, date: date ? date.unix() : 0 })
    setEmployeeFixed(user)
    setEmployeeFixedMaxDate(date && user !== '' ? date.unix() : 0)
    handleClose()
  }

  return (
    <>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <FormControl fullWidth>
        <InputLabel id="assing-user-simple-select-label">Usuário *</InputLabel>
        <Select
          labelId="assing-user-simple-select-label"
          label="Usuário *"
          value={user}
          onChange={(e) => {
            setUser(e.target.value)
          }}
          displayEmpty
        >
          <MenuItem key={''} value={''}>
            &nbsp;
          </MenuItem>
          {users.map((item: any) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
          <DateTimePicker
            minDateTime={dayjs()}
            format="DD/MM/YYYY HH:mm"
            label="Atribuir até"
            value={date}
            componentsProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            sx={{ margin: '10px 0 0 0' }}
            onChange={(newValue) => setDate(newValue)}
          />
        </DemoContainer>
      </LocalizationProvider>

      <div style={{ margin: '15px 0 0 0', textAlign: 'right' }}>
        <Button
          onClick={() => {
            handleSave()
          }}
          variant="contained"
          size="small"
          sx={{ width: 'auto' }}
        >
          Atribuir &nbsp; <KeyboardReturnIcon />
        </Button>
      </div>
    </>
  )
}

export default AssignUserForm

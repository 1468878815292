import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { request } from '../../utils/fetcher'

import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import Logo from '../../components/Logo'

const RecoverPassword = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState({
    password: '',
    password_r: '',
  })
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (
      localStorage.getItem('x-auth-chat') !== '' &&
      localStorage.getItem('x-auth-chat') !== null &&
      localStorage.getItem('x-auth-chat') !== undefined
    )
      navigate('/')
  }, [])

  const onChange = (e: any) => {
    setMessage('')
    setError('')
    setPost({ ...post, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const { password, password_r } = post
    if (password.trim() === '') setError('Preencha a Nova Senha.')
    else if (password_r.trim() === '') setError('Preencha a repetição da Nova Senha.')
    else if (password !== password_r) setError('A repetição de senha precisa ser igual.')
    else {
      setLoading(true)

      const data = await request('recover-update-password', { id, password })

      if (data?.success) {
        setMessage('Senha alterada com sucesso!')
      } else {
        setError('Token inválido.')
      }

      setLoading(false)
      handleClear()
    }
  }

  const handleClear = () => {
    setPost({
      password: '',
      password_r: '',
    })
  }

  return (
    <div
      style={{
        background: '#3800bc',
        height: '100dvh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box style={{ textAlign: 'center', paddingTop: '5%' }}>
        <Logo />

        {(error !== '' || message !== '') && (
          <div
            style={{
              width: '280px',
              marginTop: '20px',
            }}
          >
            <Alert variant="filled" icon={<></>} severity={error !== '' ? 'error' : 'success'}>
              {error !== '' ? error : ''}
              {message !== '' ? message : ''}
            </Alert>
          </div>
        )}

        <Paper style={{ width: '280px', padding: '50px 20px', marginTop: '20px' }} elevation={6}>
          <form>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <FormControl>
                <TextField
                  size="small"
                  sx={{ width: '100%', '& input': { fontSize: 14 } }}
                  value={post['password']}
                  name="password"
                  type="password"
                  autoComplete="no"
                  onChange={onChange}
                  label={<span style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>Senha</span>}
                />
              </FormControl>

              <FormControl>
                <TextField
                  size="small"
                  sx={{ width: '100%', '& input': { fontSize: 14 } }}
                  value={post['password_r']}
                  name="password_r"
                  type="password"
                  autoComplete="no"
                  onChange={onChange}
                  label={<span style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>Repetir Senha</span>}
                />
              </FormControl>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={() => navigate('/login')} style={{ fontSize: 10, textTransform: 'inherit' }}>
                  Voltar para Login
                </Button>
                <Button
                  type="submit"
                  disabled={loading}
                  size="small"
                  variant="contained"
                  style={{ fontSize: 12 }}
                  onClick={handleSubmit}
                >
                  {' '}
                  &nbsp; {loading ? (
                    <CircularProgress style={{ color: '#333', width: 14, height: 14 }} />
                  ) : (
                    `Enviar`
                  )}{' '}
                  &nbsp;{' '}
                </Button>
              </div>
            </Box>
          </form>
        </Paper>
      </Box>
    </div>
  )
}

export default RecoverPassword

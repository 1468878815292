import { FC } from 'react'

import Modal from '@mui/material/Modal'

import AssignUserForm from './AssignUserForm'
import AssignSetorForm from './AssignSetorForm'
import TransferForm from './TransferForm'
import ScheduleMessageForm from './ScheduleMessageForm'
import ScheduledMessagesList from './ScheduledMessagesList'
import DelayChat from './DelayChat'
import DelayFinish from './DelayFinish'
import Upload from './Upload'
import Messages from './Messages'

const UserModal: FC<{
  chatId?: any
  clientId?: any
  modalOpen: string
  handleClose: () => void
  setores?: any
  users?: any
  department_fixed?: any
  employee_fixed?: any
  employee_fixed_max_date?: any
  setDepartmentFixed?: any
  setEmployeeFixed?: any
  setEmployeeFixedMaxDate?: any
  setDateSchedule?: any
  scheduleDate?: any
  deviceId?: any
  clientPhone?: any
  status?: any
  refreshChatList?: any
  handleScheduleFiles?: () => void
  handleSubmitImages?: any
  clearScheduleDate?: any
  messagesModal?: any
}> = ({
  chatId,
  clientId,
  modalOpen,
  handleClose,
  setores,
  users,
  department_fixed,
  employee_fixed,
  employee_fixed_max_date,
  setDepartmentFixed,
  setEmployeeFixed,
  setEmployeeFixedMaxDate,
  setDateSchedule,
  scheduleDate,
  clientPhone,
  deviceId,
  status,
  refreshChatList,
  handleScheduleFiles,
  handleSubmitImages,
  clearScheduleDate,
  messagesModal,
}) => {
  const getModalComponent = (modalOpen: string) => {
    let minWidth = '300px'
    let obj = null

    switch (modalOpen) {
      case 'atribuir-usuario':
        obj = (
          <AssignUserForm
            clientId={clientId}
            users={users}
            handleClose={handleClose}
            employee_fixed={employee_fixed}
            employee_fixed_max_date={employee_fixed_max_date}
            setEmployeeFixed={setEmployeeFixed}
            setEmployeeFixedMaxDate={setEmployeeFixedMaxDate}
          />
        )
        break
      case 'atribuir-setor':
        obj = (
          <AssignSetorForm
            clientId={clientId}
            setores={setores}
            handleClose={handleClose}
            department_fixed={department_fixed}
            setDepartmentFixed={setDepartmentFixed}
          />
        )
        break
      case 'transfer':
        obj = <TransferForm clientId={clientId} setores={setores} users={users} handleClose={handleClose} />
        break
      case 'agendar-mensagem':
        obj = (
          <ScheduleMessageForm
            setDateSchedule={setDateSchedule}
            scheduleDate={scheduleDate}
            handleClose={handleClose}
          />
        )
        break
      case 'mensagens-agendadas':
        obj = <ScheduledMessagesList chatId={chatId} clientId={clientId} handleClose={handleClose} />
        break
      case 'adiar':
        obj = (
          <DelayChat
            chatId={chatId}
            clientPhone={clientPhone}
            deviceId={deviceId}
            status={status}
            handleClose={handleClose}
            refreshChatList={refreshChatList}
          />
        )
        break
      case 'agendarFinalizar':
        obj = (
          <DelayFinish
            chatId={chatId}
            clientPhone={clientPhone}
            deviceId={deviceId}
            status={status}
            handleClose={handleClose}
            refreshChatList={refreshChatList}
          />
        )
        break
      case 'audio-record':
        obj = (
          <Upload
            id={clientId}
            record={true}
            handleClose={handleClose}
            handleScheduleFiles={handleScheduleFiles ? handleScheduleFiles : () => {}}
            scheduleDate={scheduleDate}
            handleSubmitImages={handleSubmitImages ? handleSubmitImages : () => {}}
            clearScheduleDate={clearScheduleDate ? clearScheduleDate : () => {}}
          />
        )
        break
      case 'upload':
        obj = (
          <Upload
            id={clientId}
            handleClose={handleClose}
            handleScheduleFiles={handleScheduleFiles ? handleScheduleFiles : () => {}}
            scheduleDate={scheduleDate}
            handleSubmitImages={handleSubmitImages ? handleSubmitImages : () => {}}
            clearScheduleDate={clearScheduleDate ? clearScheduleDate : () => {}}
          />
        )
        break
      case 'messages':
        obj = (
          <Messages
            handleChange={messagesModal.handleChange}
            handleSubmit={messagesModal.handleSubmitText}
            handleClose={handleClose}
            text={messagesModal.text}
          />
        )
        break
      default:
        break
    }

    if (modalOpen === 'audio-record' || modalOpen === 'upload' || modalOpen === 'messages') minWidth = 'auto'

    return (
      <div
        style={{
          minWidth,
          maxWidth: '90%',
          maxHeight: '90%',
          background: '#fff',
          borderRadius: '8px',
          position: 'relative',
          padding: '40px',
          overflowY: 'auto',
        }}
      >
        {obj}
      </div>
    )
  }

  return (
    <Modal
      open={modalOpen !== ''}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: modalOpen !== 'agendar-mensagem' ? 'center' : 'flex-start',
          marginTop: modalOpen !== 'agendar-mensagem' ? '0' : '30px',
          width: '100%',
          height: '100%',
        }}
      >
        {getModalComponent(modalOpen)}
      </div>
    </Modal>
  )
}

export default UserModal

import { useContext, useEffect, useRef, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material'

import Layout from '../../components/Layout'
import FormGroup from '@mui/material/FormGroup'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/Close'

import { request } from '../../utils/fetcher'
import { getExtension } from '../../utils/files'
import TableFixedFiles from '../../components/TableFixedFiles'
import DefaultFileComponent from '../../components/DefaultFileComponent'
import { FilterContext } from '../../context/filter'

let deleteOnDialog = (deleteAll: number) => {}
let deleteFileCount = 0

const Files = () => {
  const defaultPost = {
    label: '',
    setor: [],
    tag: '',
    where: 0,
  }

  const whereOptions = [
    { value: 0, name: 'Todos' },
    { value: 1, name: 'Setor' },
    { value: 2, name: 'Apenas eu' },
  ]

  const drop = useRef<HTMLDivElement>(null)
  const fileInput = useRef<any>(null)
  const [sending, setSending] = useState(false)
  const [loading, setLoading] = useState(true)
  const [confirmDelete, setConfirmDelete] = useState('')
  const [fixedFiles, setFixedFiles] = useState<any[]>([])
  const [fileUpload, setFileUpload] = useState<any>(null)
  const [post, setPost] = useState(defaultPost as any)
  const [error, setError] = useState('')
  const [filterTag, setFilterTag] = useState(0)

  const { filtersOptions } = useContext(FilterContext)

  useEffect(() => {
    loadAllFixedFiles()
    setError('')

    drop.current?.addEventListener('dragover', handleDragOver)
    drop.current?.addEventListener('drop', handleDrop)

    return () => {
      drop.current?.removeEventListener('dragover', handleDragOver)
      drop.current?.removeEventListener('drop', handleDrop)
    }
  }, [])

  useEffect(() => {
    if (fileUpload) setPost({ ...post, label: fileUpload.name })
  }, [fileUpload])

  const loadAllFixedFiles = async () => {
    const data = await request(`company/fixed-files`, {}, {}, { method: 'GET' })

    if (data.success && data.files.length > 0) setFixedFiles(data.files)

    setLoading(false)
  }

  const handleDragOver = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    const { files } = e.dataTransfer

    if (files && files.length) {
      setFileUpload(files[0])
    }
  }

  const handleFile = (e: any) => {
    if (e?.target?.files) setFileUpload(e.target.files[0])
  }

  const uploadFile = async (file: any) => {
    return await request(
      `company/upload-file`,
      {
        file: [file],
      },
      {},
      { upload: true }
    )
  }

  const handleAttachFiles = async () => {
    if (fileUpload.size > 25 * 1024 * 1024) setError('Tamanho máximo de upload excedido (25MB).')
    else if (post.label === '') setError('Preencha o Label.')
    else if (post.where === 1 && post.setor.length === 0) setError('Selecione pelo menos um Setor.')
    else {
      setSending(true)

      const ext = getExtension(fileUpload.name)
      const data = await uploadFile(fileUpload)

      if (data.success && data.file?.path !== '') {
        const resp = await request(`company/create-file`, {
          file: data.file.path,
          fileSize: data.file.size,
          fileType: ext,
          ...post,
        })
      }

      setSending(false)
      handleClear()
      loadAllFixedFiles()
    }
  }

  const handleDelete = async (label: string, fileId: any, fileCount: number) => {
    if (fileCount > 1) {
      setConfirmDelete(`Você deseja remover apenas o arquivo ${label} ou também a cópia vinculada a todos os setores?`)
    } else {
      setConfirmDelete(`Tem certeza que deseja remover o arquivo ${label}?`)
    }

    deleteFileCount = fileCount

    deleteOnDialog = async (deleteAll: number) => {
      setLoading(true)
      await request(`company/delete-file/${fileId}`, { deleteAll })
      loadAllFixedFiles()
      setConfirmDelete('')
    }
  }

  const handleClear = () => {
    setPost(defaultPost as any)
    setFileUpload(null)
  }

  const onChange = (e: any) => {
    setPost({ ...post, [e.target.name]: e.target.value })
    setError('')
  }

  return (
    <div
      style={{
        overflow: 'auto',
      }}
    >
      <Modal
        open={confirmDelete !== ''}
        onClose={() => setConfirmDelete('')}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              minWidth: 'auto',
              maxWidth: '400px',
              maxHeight: '90%',
              background: '#fff',
              borderRadius: '8px',
              position: 'relative',
              padding: '40px',
              overflowY: 'auto',
            }}
          >
            <div style={{ position: 'absolute', right: 0, top: 0 }}>
              <IconButton onClick={() => setConfirmDelete('')}>
                <CloseIcon />
              </IconButton>
            </div>

            {confirmDelete}

            <div
              style={{
                margin: '15px 0 0 0',
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              {deleteFileCount > 1 && (
                <>
                  <Button onClick={() => deleteOnDialog(0)} variant="contained" size="small" sx={{ width: 'auto' }}>
                    Apenas este
                  </Button>

                  <Button onClick={() => deleteOnDialog(1)} variant="outlined" size="small" sx={{ width: 'auto' }}>
                    Em todos os setores
                  </Button>
                </>
              )}
              {deleteFileCount === 1 && (
                <Button onClick={() => deleteOnDialog(0)} variant="contained" size="small" sx={{ width: 'auto' }}>
                  Deletar
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Layout>
        <Box
          sx={{
            maxWidth: '100%',
            margin: '20px',
          }}
        >
          {fileUpload === null && (
            <div
              ref={drop}
              style={{
                width: '100%',
                border: '1px dashed',
                borderColor: sending ? '#ccc' : '#000',
                color: sending ? '#ccc' : '#000',
                padding: '40px 20px 10px',
                marginBottom: '40px',
              }}
            >
              <input style={{ display: 'none' }} ref={fileInput} type="file" onChange={handleFile} />

              <div
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  gap: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '40px',
                }}
                onClick={() => (sending ? () => {} : fileInput.current?.click())}
              >
                <>
                  <span> Arraste um arquivo ou clique para realizar o upload. </span>{' '}
                  <CloudUploadIcon fontSize={'medium'} />
                </>
              </div>
            </div>
          )}

          {fileUpload !== null && (
            <div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div>
                  <DefaultFileComponent file={fileUpload} />
                  {sending ? <LinearProgress /> : <div style={{ height: '4px' }}>&nbsp;</div>}
                </div>
                <div style={{ flex: 1 }}>
                  <FormGroup>
                    <Grid container spacing={2} sx={{ padding: '0 20px' }}>
                      <Grid item xs={12}>
                        <FormControl fullWidth size="small" style={{ position: 'relative' }}>
                          <span
                            style={{
                              position: 'absolute',
                              zIndex: 2,
                              right: 10,
                              top: 10,
                            }}
                            title="Adicione um rótulo para exibição no chat do destinatário. Este texto atua como uma nota de rodapé, aparecendo abaixo do seu arquivo."
                          >
                            <InfoOutlinedIcon fontSize="small" />
                          </span>
                          <TextField
                            id="text-label"
                            name="label"
                            label="Label *"
                            variant="outlined"
                            size="small"
                            value={post['label']}
                            onChange={onChange}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="select-where">Salvar para *</InputLabel>
                          <Select
                            labelId="select-where"
                            name="where"
                            label="where"
                            value={post['where']}
                            input={<OutlinedInput label="Salvar para *" />}
                            onChange={onChange}
                          >
                            {whereOptions.map((item: any) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth size="small" style={{ position: 'relative' }}>
                          <span
                            style={{
                              position: 'absolute',
                              zIndex: 2,
                              right: 30,
                              top: 10,
                            }}
                            title="A tag selecionada será automaticamente atribuída ao contato sempre que o arquivo for enviado. (Opcional)"
                          >
                            <InfoOutlinedIcon fontSize="small" />
                          </span>
                          <InputLabel id="select-tag">Tag</InputLabel>
                          <Select
                            labelId="select-tag"
                            name="tag"
                            label="Tag"
                            value={post['tag']}
                            input={<OutlinedInput label="Tag" />}
                            onChange={onChange}
                            displayEmpty
                          >
                            <MenuItem value=""> &nbsp; </MenuItem>
                            {filtersOptions.tags.map((item: any) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {post['where'] === 1 && (
                        <Grid item xs={6}>
                          <FormControl fullWidth size="small">
                            <InputLabel id="select-setor">Selecione o Setor *</InputLabel>
                            <Select
                              labelId="select-setor"
                              multiple
                              name="setor"
                              label="Selecione o Setor *"
                              value={post['setor'] || []}
                              input={<OutlinedInput label="Selecione o Setor *" />}
                              onChange={onChange}
                              renderValue={(selected) => {
                                return filtersOptions.setores
                                  .filter((item: any) => selected.indexOf(item.value) > -1)
                                  .map((item: any) => item.name)
                                  .join(', ')
                              }}
                              displayEmpty
                            >
                              {filtersOptions.setores.map((item: any) => (
                                <MenuItem key={item.value} value={item.value}>
                                  <Checkbox checked={post['setor'] && post['setor'].indexOf(item.value) > -1} />
                                  <ListItemText primary={item.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </FormGroup>

                  {error !== '' && (
                    <div style={{ padding: '20px' }}>
                      <Alert severity="error">{error}</Alert>
                    </div>
                  )}
                </div>
              </div>

              <div style={{ margin: '40px 0', display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  disabled={sending}
                  onClick={() => {
                    handleClear()
                  }}
                  size="small"
                  sx={{ width: 'auto' }}
                >
                  Limpar
                </Button>

                <Button
                  disabled={sending || fileUpload === null}
                  onClick={() => {
                    handleAttachFiles()
                  }}
                  variant="contained"
                  size="small"
                  sx={{ width: 'auto' }}
                >
                  Salvar
                </Button>
              </div>
            </div>
          )}
        </Box>
        <Box
          sx={{
            margin: '20px',
          }}
        >
          {loading && <LinearProgress />}
          {fixedFiles.length > 0 && fixedFiles[0].FixedFiles_id !== null && (
            <>
              <div style={{ width: '50dvw', margin: '0 0 15px 0' }}>
                <FormControl fullWidth size="small" style={{ position: 'relative' }}>
                  <InputLabel id="select-tag">Tag</InputLabel>
                  <Select
                    labelId="select-tag"
                    name="tag"
                    label="Tag"
                    value={filterTag}
                    input={<OutlinedInput label="Tag" />}
                    onChange={(e) => setFilterTag(Number(e.target.value))}
                    displayEmpty
                  >
                    <MenuItem value=""> &nbsp; </MenuItem>
                    {filtersOptions.tags.map((item: any) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <TableFixedFiles files={fixedFiles} handleDelete={handleDelete} filterTag={filterTag} showTag={true} />
            </>
          )}
        </Box>
      </Layout>
    </div>
  )
}

export default Files

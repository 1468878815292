import { FC, useState } from 'react'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { request } from '../../../../../utils/fetcher'

const AssignSetorForm: FC<{
  clientId: any
  handleClose: () => void
  setores: any
  department_fixed: any
  setDepartmentFixed: any
}> = ({ clientId, handleClose, setores, department_fixed, setDepartmentFixed }) => {
  const [setor, setSetor] = useState(department_fixed)

  const handleSave = async () => {
    await request(`messages/${clientId}/assign-setor`, { setor })
    setDepartmentFixed(setor)
    handleClose()
  }

  return (
    <>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <FormControl fullWidth>
        <InputLabel id="assing-user-simple-select-label">Departamento *</InputLabel>
        <Select
          labelId="assing-user-simple-select-label"
          label="Departamento *"
          value={setor}
          onChange={(e) => {
            setSetor(e.target.value)
          }}
          displayEmpty
        >
          <MenuItem key={''} value={''}>
            &nbsp;
          </MenuItem>
          {setores.map((item: any) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ margin: '15px 0 0 0', textAlign: 'right' }}>
        <Button
          onClick={() => {
            handleSave()
          }}
          variant="contained"
          size="small"
          sx={{ width: 'auto' }}
        >
          Atribuir &nbsp; <KeyboardReturnIcon />
        </Button>
      </div>
    </>
  )
}

export default AssignSetorForm
